const colleges = require('./colleges.json');

//console.log(colleges[0])
/*for (var i = 0; i < data.length; i++)
{
    var obj = data[i];
    console.log(`Name: ${obj.last_name}, ${obj.first_name}`);

    
}*/

//let sortedColleges = colleges.sort((a, b) => a.name.localeCompare(b.name)


module.exports = colleges;