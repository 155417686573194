import "./textbook.css";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Stack, IconButton, CardHeader, Alert, Collapse} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const Textbook = ({
    id, 
    title, 
    year,
    authors,
    edition,
    publisher,
    isbn,
    numChapters,
    cover,
    chapters}) => {
    
    const [openAlert, setOpenAlert] = useState(false);
    const [bookmarkClicked, setBookmarkClicked] = useState(false);

    useEffect(() => {
        const getSearchResults = async () => {
        try {
            await axios.get('/api/auth/refresh-token').then(res => loadBookmarks(res.data)).catch(error => console.log(error));
        } catch (err) {
            console.log(err)
        }
    };
    getSearchResults();
    }, [bookmarkClicked]);


    const loadBookmarks = (token) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.accessToken}`
            },
        };
        if (token.accessToken) {
            axios.get(`/api/textbooks/user/${id}`, config).then(res => testFunc(res.data.bookmarks)).catch(error => console.log(error));
        }
    }

    var newTitle = title;
    if (title.length > 24) {
        newTitle = title.substr(0, 24) + '...';
    }

    var newAuthors = authors;
    if (authors.length > 2) {
        newAuthors.length = 3;
        newAuthors[2] = '...'
    }
    
    const [filled, setFilled] = useState(false);
    
    //unbookmark
    const handleBookmarkFilled = () => {
        axios.get('/api/auth/refresh-token') 
        .then(res => unBookmark(res))
        .catch(error => console.log(error));
        setBookmarkClicked(true);
    };

    const unBookmark = async (res) => {
        try {
        if (res.data.accessToken) {
            await axios.get(`/api/textbooks/test/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data.accessToken}`
                },
            }).then(async () => await setFilled(false))
            .catch(error => console.log(error));
        } else {
            setOpenAlert(true);
            setTimeout(() => {
                setOpenAlert(false);
            }, 2000);
        }
        } catch (err) {
            console.log(err)
        }
    };

    
    //create bookmark
    const handleBookmark = () => {
        //console.log('id: ', id)
        axios.get('/api/auth/refresh-token')
        .then(
            res => {
            if (res.data.accessToken) {
            axios.get(`/api/textbooks/test/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.accessToken}`},
            }
            ).then(async () => await setFilled(true))}
            else {setOpenAlert(true);
                setTimeout(() => {
                    setOpenAlert(false);
                }, 2000);}
        })
        .catch(error => console.log(error));
        //console.log('filled: ', filled)
    };


    const testFunc = async (bookmarks) => {
        let temp = true;
        for (let i = 0; i < bookmarks.length; i++) {
            if (bookmarks[i].toString() === id.toString()) {
                setFilled(true);
                temp = false;
            }
        }
        if (temp && filled){
            setFilled(false);
        }
    };

    return (
        <Card sx={{height:304, width:390, bgcolor:"#1a2e44", boxShadow:'0 3px 10px rgb(0 0 0 / 0.4)'}}>
            { (filled) ? 
            <CardHeader
                disableTypography
                title={<Typography variant="h5" color="#fff" fontWeight="bold" component="div"> {newTitle}</Typography>}
                action={
                <IconButton onClick={handleBookmarkFilled} aria-label="add to favorites" sx={{ml:2, color:"#c4c4c4"}}>
                    <BookmarkIcon/>
                </IconButton>
                }
            />
            :
            <CardHeader
                disableTypography
                title={<Link to={{ pathname: `/${chapters[0]}/comments`, state: { chapters: chapters }}} style={{ textDecoration: "none" }}>
                <Typography variant="h5" color="#fff" fontWeight="bold" component="div"> {newTitle}</Typography></Link>}
                action={
                <IconButton onClick={handleBookmark} aria-label="add to favorites" sx={{ml:2, color:"#c4c4c4"}}>
                    <BookmarkBorderIcon/>
                </IconButton>
                }
            />
            }
            <Collapse in={openAlert}>
                    <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenAlert(false);}} >
                        Please <strong>login</strong> to bookmark a textbook! 
                    </Alert>
            </Collapse>
            <Link to={{ pathname: `/${chapters[0]}/comments`, state: { chapters: chapters }}} style={{ textDecoration: "none" }}>
                <CardActionArea>
                    <CardContent sx={{pt:0}}>
                        <Stack direction="row" spacing={2}>
                            <img src={cover} alt="cover"/>
                            <Box>
                                {/*<Typography gutterBottom variant="h5" color="#fff" fontWeight="bold" component="div">{title}</Typography>*/}
                                <Typography variant="body1" color="#e1e1ea">
                                Author(s):  <em>{newAuthors.join(", ")}</em>
                                </Typography>
                                <Typography variant="body1" color="#e1e1ea">
                                Publisher:  <em>{publisher}</em>
                                </Typography>
                                <Typography variant="body1" color="#e1e1ea">
                                Edition:  <em>{edition}</em> 
                                </Typography>
                                <Typography variant="body1" color="#e1e1ea">
                                Year:  <em>{year}</em> 
                                </Typography>
                                <Typography variant="body1" color="#e1e1ea">
                                ISBN:  <em>{isbn}</em> 
                                </Typography>
                                <Typography variant="body1" color="#e1e1ea">
                                Number of Chapters:  <em>{numChapters}</em> 
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default Textbook;

/*
            <div className="textbook__info">
                <p className="info__title">{title}</p>

                <p className="info__year">{year}</p>

                <p className="info__authors">{authors[0]}</p>

            </div>
            */



/*
        <Box sx={{pt: 1, width:150}}>
            <img onClick={textbookChapter} src={cover} style={{ cursor: "pointer"}}/>

            <center className="info__title">{title}</center>

            <center className="info__authors">{authors[0]}</center>

            <center className="info__edition">{edition} Edition</center>

</Box>*/