import { Typography, Card, Stack} from '@mui/material';


const CardAI = ( {title, desc, icon} ) =>  {
    return (
     <Card sx={{ boxShadow:3, borderRadius: 2, height:220, width:300, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
        {icon}
        <Stack p={3} pt={0}>
            <Typography fontWeight="bold" fontSize="20px">{title}</Typography>
            <Typography fontSize="16px">{desc}</Typography>
        </Stack>
    </Card>
    )
}

export default CardAI;