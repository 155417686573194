import React from 'react';
import { Grid, Typography, Button, Box, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';

const ErrorScreen = () => {
    const history = useHistory();

    const homeHandler = () => {
        history.push("/");
        window.location.reload();
    }

    return (
        <Stack display="flex" 
            alignItems="center"
            justifyContent="center"
            sx={{mt: 2, '&:hover': {cursor:"pointer"}}}
            onClick = {homeHandler}>
                
            <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:70, fontWeight: "900", color:"#fff"}}>
            Error 404: Page not found
            </Typography>
            <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:40, fontWeight: "900", color:"#fff"}}>
             Click here to return to home screen
            </Typography>
            <br/><br/><br/><br/><br/>
        </Stack>
    );
}

export default ErrorScreen;