import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios";
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { SvgIcon, Typography, Toolbar, Tooltip, Box, Button, AppBar, Modal, 
        Menu, MenuItem, Fab, Stack, InputBase, Drawer, List, ListItem, 
        ListItemIcon, ListItemText, IconButton } from '@mui/material';
import Select from 'react-select';
import { ReactComponent as Test } from '../svg/test.svg';
import GoogleLogin from 'react-google-login';
import GoogleIcon from '@mui/icons-material/Google';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material/styles';
import "./Navbar.css";
import TOS from '../../legalDocs/TOS.pdf';
import Privacy from '../../legalDocs/Privacy.pdf';
import colleges from "../Dropdown/collegeDropdown.js"
import majors from "../Dropdown/majorsDropdown.js"

const userStyle = {
    color: 'common.white',
    bgcolor: '#33365b',
    boxShadow: 4,
    '&:hover': {
      bgcolor: '#232647',
    },
  }
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, .08),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '50ch',
        },
      },
    },
  }));
  
  const Navbar = () => {
      const theme = useTheme();
      const renderAppBar = useMediaQuery(theme.breakpoints.down('md'));
  
      //localStorage.removeItem("authToken") 
      const history = useHistory();
      //const [profileComplete, setProfileComplete] = useState(false);
      const [username, setUsername] = useState("");
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      const [confirmPassword, setConfirmPassword] = useState("");
      const [uni, setUni] = useState("");
      const [major, setMajor] = useState("");
      const [gender, setGender] = useState("");
      const [gradyear, setGradYear] = useState("");
      const [degree, setDegree] = useState("");
      const [error, setError] = useState("");
      const [anchorElUser, setAnchorElUser] = useState(null);
      const [searchInput, setSearchInput] = useState('');
      const [loggedIn, setLoggedIn] = useState(false);
      const config = {
          header: {
              "Content-Type": "application/json",
          },
      };
  
      const logger = (token) => {
          if(token.accessToken) {
              setLoggedIn(true);
              /*axios.get(`/api/auth/profile`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${token.accessToken}`}})
              .then(res => closeLoginOpenProfile(res.data)).catch(error => console.log(error));*/
              axios.get(`/api/auth/profile`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${token.accessToken}`}})
              .catch(error => console.log(error));
          } else {
              if (loggedIn) {
                  logoutHandler();
              }
          }
      }
  
      //console.log(colleges[0]["label"])
  
      //useEffect(() => {
      //}, [history]);
      axios.get(`/api/auth/refresh-token`).then(res => logger(res.data)).catch(error => console.log(error));
  
      const registerHandler = async (e) => {
          e.preventDefault();
          if (error.length > 4 && error.slice(4) === "idpi") {
            setError("");
          }
          
          if (password !== confirmPassword) {
              setPassword("");
              setConfirmPassword("");
              setTimeout(() => {
                  setError("");
              }, 5000);
              return setError("Passwords do not match");
          }

          if (password.length < 6) {
              setPassword("");
              setConfirmPassword("");
              setTimeout(() => {
                  setError("");
              }, 5000);
              return setError("Password must be at least 6 characters in length");
          }
          
        //   var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //   if (!pattern.test(email)) {
        //     setEmail("");
        //     setTimeout(() => {
        //         setError("");
        //     }, 5000);
        //     return setError("Please provide a valid email");
        //   }
  
          try {
              await axios.post(
                  "/api/auth/register",
                  {
                      username,
                      email,
                      password,
                  },
                  config
              );
  
              //localStorage.setItem("authToken", data.token);
  
              //history.push("/");
              closeRegisterOpenLogin();
          } catch (error) {
              setError(error.response.data.error);
              setTimeout(() => {
                  setError("");
              }, 5000);
          }
      };
  
      const loginHandler = async (e) => {
          e.preventDefault();
  
          try {
              const { data } = await axios.post(
                  "/api/auth/login",
                  { email, password },
                  config
              );
  
              //localStorage.setItem("authToken", data.token);
              if (data.token.accessToken) {
                  //console.log(data.token)
                  setLoggedIn(true);
                  localStorage.setItem("authToken", true);
              }
              //window.location.reload();
              //history.push("/");
              /*await axios.get(`/api/auth/profile`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.token.accessToken}`}}).
              then(res => closeLoginOpenProfile(res.data)).catch(error => console.log(error));*/
              const sub = await axios.get(`/api/auth/subscription`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.token.accessToken}`}});
              if (sub)
                localStorage.setItem("sub", sub.data);
              else
                localStorage.setItem("sub", false);
              ////history.push("/"); <- should be this
              ////history.push("/");
              handleLoginClose();
          } catch (error) {
              setError(error.response.data.error);
              setTimeout(() => {
                  setError("");
              }, 5000);
          }
      };
  
      const profileHandler = async (e) => {
          e.preventDefault();
  
          try {
              const {data} = await axios.get(`/api/auth/refresh-token`)
              await axios.put(
                  "/api/auth/profile",
                  {
                      uni,
                      major,
                      gender,
                      gradyear,
                      degree
                  },
                  {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.accessToken}`}}
              );
              localStorage.setItem("authToken", true);
              //setProfileComplete(true);
              window.location.reload();
              
              
          } catch (error) {
              console.log('err')
              setError(error.response.data.error);
              setTimeout(() => {
                  setError("");
              }, 5000);
          }
      };
  
      const responseSuccessGoogleReg = async (response) => {
          try {
              const tokenId = response.tokenId;
              await axios.post(
                  "/api/auth/googleRegister",
                  { tokenId },
                  config
              );
  
              //localStorage.setItem("authToken", data.token);
              //history.push("/"); <- should be this
              //history.push("/");
              window.location.reload();
          } catch (error) {
              setError(error.response.data.error);
              setTimeout(() => {
                  setError("");
              }, 5000);
          }
      };
  
          const responseSuccessGoogleLogin = async (response) => {
          try {
              const tokenId = response.tokenId;
              const { data } = await axios.post(
                  "/api/auth/googleLogin",
                  { tokenId },
                  config
              );
  
              //localStorage.setItem("authToken", data.token);
              //history.push("/"); <- should be this
              //history.push("/success");
              if (data.token.accessToken) {
                  setLoggedIn(true);
                  localStorage.setItem("authToken", true);
              }
  
              /*await axios.get(`/api/auth/profile`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.token.accessToken}`}}).
              then(res => closeLoginOpenProfile(res.data)).catch(error => console.log(error));*/
              const sub = await axios.get(`/api/auth/subscription`, {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.token.accessToken}`}});
              if (sub)
                localStorage.setItem("sub", sub.data);
              else
                localStorage.setItem("sub", false);

              window.location.reload();
          } catch (error) {
              setError(error.response.data.error);
              setTimeout(() => {
                  setError("");
              }, 5000);
          }
      };
  
      const responseFailureGoogle = async (response) => {
          setError(response.error);
          setTimeout(() => {
              setError("");
          }, 5000);
      };
  
      //for modal and close button
      const [isRegisterModalOpen, toggleRegisterModal] = React.useState(false);
      const [isLoginModalOpen, toggleLoginModal] = React.useState(false);
      const isProfileModalOpen = false;
  
    
      const handleRegisterClose = () => {
          toggleRegisterModal(false);
      };
        
      const handleRegisterOpen = () => {
          toggleRegisterModal(true);
      };
  
      const closeRegisterOpenLogin = () => {
          handleRegisterClose();
          handleLoginOpen();
      };
  
      const handleLoginClose = () => {
          toggleLoginModal(false);
      };
        
      const handleLoginOpen = () => {
          toggleLoginModal(true);
      };
  
      const closeLoginOpenRegister = () => {
          handleLoginClose();
          handleRegisterOpen();
      };
   
      /* force user to complete profile after login
      const closeLoginOpenProfile =  (data) => {
          
          if (!data) {
              handleLoginClose();
              handleProfileOpen();
          } 
      }; */
  
    const logoutHandler = async () => {
          try {
              await axios.post("/api/auth/logout").then(res => fullyLogout(res.data));
          } catch (err) {
              console.log(err)
          }
        //localStorage.removeItem("authToken");
          
    };

    const bookmarkHandler = async () => {
         history.push('/bookmarks');
         setAnchorElUser(false);
    }

    const userProfileHandler = async () => {
        history.push("/profile");
        setAnchorElUser(false);
   }
        
    const fullyLogout = (msg) => {
        if (msg === 'OK') {
            window.location.reload();
            localStorage.removeItem("authToken");
            localStorage.removeItem("sub");
            //setLoggedIn(false);
        }
    }
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    const homeHandler = () => {
      history.push("/");
      window.location.reload();
    }
  
    const summaryHandler = () => {
      //history.push("/search/?query=2013%202014%202015%202016%202017%202019&subject=&publisher=&edition=&year=&page=1&sortBy=Default");
      axios.get(`/api/textbooks/years`)
                .then(res => loadSummaries(res.data)).catch(error => console.log(error));
    }

    const pricingHandler = () => {
        history.push("/pricing");
    }

    const loadSummaries = (data) => {
        const query = data + "";
        console.log('data:', query);
        history.push(`/search/?query=${query}&subject=&publisher=&edition=&year=&page=1&sortBy=Default`);
        window.location.reload();
    }
  
    const applyHandler = () => {
      window.open("https://bit.ly/EZread");
    }
  
    const contactHandler = () => {
      window.open("https://forms.gle/b5Zj51kKFeQcLbHq7");
    }
  
    const aboutHandler = () => {
        history.push("/about")
        //window.location.reload();
    }
    
  
    const handleSearch = (e) => {
      e.preventDefault();
      if (searchInput === ''){
          return;
      }
      //query=e.target.value;
      history.push(`/search/?query=${searchInput}&subject=&publisher=&edition=&year=&page=1&sortBy=Default`);
      //history.push(`/search/${searchInput}`);
      window.location.reload();
    };  

    const DrawerComponent = () => {
        const [openDrawer, setOpenDrawer] = useState(false);
        return (
            <>
            <Drawer
            anchor = 'right'
            onClose = {() => setOpenDrawer(false)}
            open = {openDrawer}>
                <List>
                    <ListItem button onClick={summaryHandler}>
                        <ListItemIcon>
                            <ListItemText>Summaries</ListItemText>
                        </ListItemIcon>
                    </ListItem> 
                    {/*
                    <ListItem button onClick={() => history.push("/about")}>
                        <ListItemIcon>
                            <ListItemText>AI Tools</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    */}
                    <ListItem button onClick={pricingHandler}>
                        <ListItemIcon>
                            <ListItemText>Pricing</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem button onClick={applyHandler}>
                        <ListItemIcon>
                            <ListItemText>Apply</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem button onClick={aboutHandler}>
                        <ListItemIcon>
                            <ListItemText>About</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem divider button onClick={contactHandler}>
                        <ListItemIcon>
                            <ListItemText>Contact</ListItemText>
                        </ListItemIcon>
                    </ListItem>
    
                    {loggedIn ? <>
                        <ListItem button onClick={userProfileHandler}>
                            <ListItemText>Profile</ListItemText>
                        </ListItem>
                        <ListItem button onClick={bookmarkHandler}>
                            <ListItemText>Bookmarks</ListItemText>
                        </ListItem>
                        <ListItem button onClick={logoutHandler}>
                            <ListItemText>Logout</ListItemText>
                        </ListItem>
                    </> : <>
                        <ListItem button onClick={handleLoginOpen}>
                            <ListItemText>Login</ListItemText>
                        </ListItem>
                        <ListItem button onClick={handleRegisterOpen}>
                            <ListItemText>Register</ListItemText>
                    </ListItem>
                    </>}
                </List>
            </Drawer>
            <IconButton onClick = {() => setOpenDrawer(!openDrawer)} sx={{ml:'auto'}}>
                <MenuIcon sx={{color:'common.white', fontSize:'30px'}}/>
            </IconButton>
            </>
        )
      }
  
    return (
      <Box>
        <AppBar position="fixed">
          <Toolbar sx={{backgroundColor: "#101d2c"}}>
            <Stack onClick={homeHandler} direction='row' sx={{ml:"1.8vw", alignItems: "center", '&:hover': {cursor:"pointer"}}}>
              <SvgIcon  component={Test} inheritViewBox sx={{fontSize:50}}/>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml:1, mr:"1.7vw", mt:"2px"}}>
                EZread
              </Typography>
              <Box component="form" ml={1} onClick={{}} onSubmit={handleSearch}>
                <Search>
                    <SearchIconWrapper>
                    <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    type="search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    />
                </Search>
                </Box>
            </Stack>
            {renderAppBar ? (
                <DrawerComponent/>
            ) : (<>
                <Typography sx={{flexGrow:1}}/>
                {/*<Button href="/" color="inherit" sx={{ml:4, textTransform: "none", fontSize:20}}>EZread</Button>*/}
                <Button color="inherit" disableRipple onClick={summaryHandler} style={{float:"right"}} sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>Summaries</Button>
                {/*<Button href="/ai" disableRipple color="inherit" component={Link} to="/ai" sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>AI Tools</Button>*/}
                <Button color="inherit" disableRipple onClick={pricingHandler} style={{float:"right"}} sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>Pricing</Button>
                <Button disableRipple color="inherit" onClick={applyHandler} sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>Apply</Button>
                <Button disableRipple color="inherit" onClick={aboutHandler} sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>About</Button>
                <Button disableRipple color="inherit" onClick={contactHandler} sx={{mr:"0.4vw", textTransform: "none", fontSize:15}}>Contact</Button>
                { loggedIn ?
                <Box sx={{ flexGrow: 0, ml:"0.4vw", mr:"1.8vw" }}>
                    <Tooltip title="Account Settings">
                    <Fab onClick={handleOpenUserMenu} sx={userStyle} style={{width:38, height:38}}>
                        <PersonIcon/>
                    </Fab>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px', '& .MuiMenu-paper':{color:"#fff", bgcolor:"#254468"} }}
                    color="primary"
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                     <MenuItem key="Profile" onClick={userProfileHandler}>
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <MenuItem key="Bookmarks" onClick={bookmarkHandler}>
                        <Typography textAlign="center">Bookmarks</Typography>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={logoutHandler}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    </Menu>
                </Box> : <>
                <Box borderRight={1} marginRight={1}>
                <Button color="inherit" disableRipple sx={{mr:1, textTransform: "none", fontSize:15}} onClick={handleLoginOpen}>Login</Button >
                </Box>
                <Button variant="contained" size="large" sx={{borderRadius: 5, backgroundColor: "#3671E9", ml:2, mr:6, textTransform: "none"}} 
                onClick={handleRegisterOpen} style={{fontSize:15}}>
                Register
                </Button>
                </>}
            </>)}
            
          </Toolbar>
        </AppBar>
        <Toolbar/>
        { isRegisterModalOpen ?
            <Modal 
                onClose={handleRegisterClose} 
                open={isRegisterModalOpen} 
                style = {{ 
                    display: 'flex', 
                    alignItems: 'initial', 
                    justifyContent: 'center',
                    marginTop: 110,
                    overflow: "auto"
                }}
            >
                <Box component="form" onSubmit={registerHandler}>
                    < span class="close" onClick={handleRegisterClose}>&times;</span>
                    <div className="register-screen__form">
                            <h2 style = {{fontFamily: "Rubik", fontWeight: 900}} className="register-screen__title"> Create your account </h2>
                            {error && <span className="error-message">{error}</span>}
                            <div className="form-group">
                                <label htmlFor="name" > Username </label>
                                <input
                                    required
                                    id="name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" > Email </label>
                                <input
                                    type="email"
                                    required
                                    id="email"
                                    //placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" > Password </label>
                                <input
                                    type="password"
                                    required
                                    id="password"
                                    autoComplete="true"
                                    //placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmpassword" > Confirm Password </label>
                                <input
                                    type="password"
                                    required
                                    id="confirmpassword"
                                    autoComplete="true"
                                    //placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            {/*<button type="submit" className="btn btn-primary" style={{marginTop:10}} onClick={closeRegisterOpenLogin}>
                                Create your account
                            </button>*/}
                            <Button
                                    type="submit"
                                    tabIndex={3}
                                    sx={{textTransform: "none", mt:"12px", mb:"8px"}}
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    fullWidth
                                    style={{fontFamily: "Rubik", float:"left", fontSize: 13}} 
                                    onClick={registerHandler}
                            >
                                        Create your account
                            </Button>
                            <span className="register-screen__or">
                                or
                            </span>
                            <GoogleLogin
                                clientId="389569918389-kc0j1rk0t663malqifnh5jagc4el2d9j.apps.googleusercontent.com"
                                render={renderProps => (
                                    <Button
                                    fullWidth
                                    variant = "contained"
                                    style={{fontFamily: "Rubik"}}
                                    size="large" 
                                    sx={{textTransform: "none", justifyContent:"left"}}
                                    disabled={renderProps.disabled}
                                    startIcon={
                                        <GoogleIcon sx={{ color: "lightBlue[500]",}}/>
                                    }
                                    onClick={renderProps.onClick}
                                    >
                                        <p style={{fontSize: 13}}>&emsp;&emsp;&emsp;&emsp;&emsp;Continue with Google</p>
                                    </Button>
                                )}
                                icon
                                buttonText="Register with Google"
                                onSuccess={responseSuccessGoogleReg}
                                onFailure={responseFailureGoogle}
                                cookiePolicy={'single_host_origin'}
                                  />
                            <span className="register-screen__subtext">
                                Already have an account? <Link onClick={closeRegisterOpenLogin} style= {{ textDecoration: "none"}}>Sign in</Link>
                            </span>
                            <span className="register-screen__terms">
                                By creating your EZread account, you agree to the <a href={TOS} target="_blank" rel="noreferrer" style= {{ textDecoration: "none"}}>Terms of Use </a> 
                                and the <a href={Privacy} target="_blank" rel="noreferrer" style= {{ textDecoration: "none"}}>Privacy Policy</a>  
                            </span>
                    </div>
                </Box>
            </Modal> : null}
        {isLoginModalOpen ? 
        <Modal 
            onClose={handleLoginClose} open={isLoginModalOpen} 
            style = {{ 
                display: 'flex', 
                alignItems: 'initial', 
                justifyContent: 'center',
                marginTop: 110,
                overflow: "auto"
            }}
        >
            <Box>
                <span className="close" onClick={handleLoginClose}>&times;</span>
                <div>
                    <form onSubmit={loginHandler} className="login-screen__form">
                        <h2 className="login-screen__title" style = {{fontWeight: 900, fontFamily: "Rubik"}} > Sign in to your account </h2>
                        {error && <span className="error-message">{error}</span>}
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                required
                                id="email"
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                tabIndex={1}
                                size="0"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">
                                Password: 
                                <Link to="/forgotpassword" className="login-screen__forgotpassword" tabIndex={4} style= {{ textDecoration: "none"}}>
                                    Forgot Password?
                                </Link>
                            </label>
                            <input
                                type="password"
                                required
                                id="password"
                                autoComplete="true"
                                placeholder="Enter password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                tabIndex={2}
                            />
                             
                        </div>
                        {/*<button type="submit" className="btn btn-primary" tabIndex={3} style={{marginTop:10}}>
                            Sign in
                        </button>*/}
                        <Button
                            type="submit"
                            tabIndex={3}
                            sx={{textTransform: "none", mt:"12px", mb:"8px"}}
                            variant="contained"
                            size="large"
                            color="secondary"
                            fullWidth
                            style={{fontFamily: "Rubik", float:"left", fontSize: 13}} 
                            //onClick={{}}
                        >
                            Sign in
                        </Button>
  
                        <span className="login-screen__or">
                            or
                        </span>
                        <GoogleLogin
                            clientId="389569918389-kc0j1rk0t663malqifnh5jagc4el2d9j.apps.googleusercontent.com"
                            render={renderProps => (
                                <Button
                                    fullWidth
                                    variant = "contained"
                                    style={{fontFamily: "Rubik"}}
                                    size="large" 
                                    sx={{textTransform: "none", justifyContent:"left"}}
                                    disabled={renderProps.disabled}
                                    startIcon={
                                        <GoogleIcon sx={{ color: "lightBlue[500]"}}/>
                                    }
                                    onClick={renderProps.onClick}
                                >
                                    <p style={{fontSize: 13}}>&emsp;&emsp;&emsp;&emsp;&emsp;Continue with Google</p>
                                </Button>
                               
                            )}
                            buttonText="Login with Google"
                            onSuccess={responseSuccessGoogleLogin}
                            onFailure={responseFailureGoogle}
                            cookiePolicy={'single_host_origin'}
                        /> 
                        <span className="login-screen__subtext">
                            Don't have an account? <Link onClick={closeLoginOpenRegister} style= {{ textDecoration: "none"}}>Register</Link>
                        </span>
                    </form>
                </div>
            </Box>
        </Modal> : null}
        {isProfileModalOpen ? 
          <Modal 
              open={isProfileModalOpen} 
              style = {{ 
                  display: 'flex', 
                  alignItems: 'initial', 
                  justifyContent: 'center',
                  marginTop: 110,
                  overflow: "auto"
              }}
          >
              <Box>
                  <div>
                      <form onSubmit={profileHandler} className="profile-screen__form">
                          <h2 className="profile-screen__title" style = {{fontWeight: 550, fontFamily: "Rubik", marginBottom:5}} > Complete your Profile </h2>
                          <p style={{fontSize:"10px", marginBottom:10}}>*Note: Please fill out all fields!</p>
                          {error && <span className="error-message">{error}</span>}
                          <div className="form-group">
                              {/*<TextField
                              size="small"
                              variant="outlined"
                              select
                              label="School"
                              value={uni}
                              onChange={(e) => setUni(e.target.value)}
                              margin="normal"
                              fullWidth
                              //sx={{ "& .MuiSelect-select": {color: "#000", border: "#000", width:"20ch"}, }}
                              >
                                  {colleges.map((college) => (
                                  <MenuItem value={college["name"]}>{college["institution"]}</MenuItem>
                                  ))}
                                  <MenuItem value="Other">Other</MenuItem>
                                  <MenuItem value="Other (High School)">Other (High School)</MenuItem>
                              </TextField>
                              <Autocomplete
                                  disablePortal
                                  options={colleges.map((college) => college.label)}
                                  renderInput={(params) => <TextField {...params} />}
                                  />*/}
                              <Select required placeholder="School" options={colleges} onChange={(e) => setUni(e.label)} />
  
                          </div>
                          <div className="form-group">
                              <Select placeholder="Major" options={majors} onChange={(e) => setMajor(e.value)} />
                          </div>
                          <div className="form-group">
                              <Select placeholder="Gender" options={[{"label": "Male"}, {"label": "Female"}, {"label": "Other"}]} onChange={(e) => setGender(e.label)} />
                          </div>
                          <div className="form-group">
                              <Select placeholder="Graduation Year" options={
                                  [{"label": "2022"}, {"label": "2023"}, {"label": "2024"}, {"label": "2025"}, 
                                  {"label": "2026"}, {"label": "2027"}, {"label": "2028"}, {"label": "Other"}]
                                  } onChange={(e) => setGradYear(e.label)} />
                          </div>
                          <div className="form-group">
                              <Select placeholder="Education Level" options={
                                  [{"label": "High School"}, {"label": "Undergraduate"}, {"label": "Graduate"}, {"label": "Other"}]
                                  } 
                                  onChange={(e) => setDegree(e.label)}/>
                          </div>
                          <Button
                              type="submit"
                              tabIndex={3}
                              sx={{textTransform: "none", mt:"12px", mb:"8px"}}
                              variant="contained"
                              size="large"
                              color="primary"
                              fullWidth
                              style={{fontFamily: "Rubik", float:"left"}} 
                          >
                              SUBMIT
                          </Button>
                      </form>
                  </div>
              </Box>
          </Modal> : null}
      </Box>
    );
  }
  
  export default Navbar;