import React from 'react';
import { Switch, Route } from "react-router-dom";
//import ReactGA from 'react-ga';
// Routing
// Screens
import BookmarksScreen from "./components/screens/BookmarksScreen";
import PricingScreen from "./components/screens/PricingScreen";
import ProfileScreen from "./components/screens/ProfileScreen";
import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import SearchResults from "./components/search/SearchResults.js"
import Navbar from "./components/common/Navbar.js";
import Footer from "./components/common/Footer.js";
import HomeScreen from "./components/screens/HomeScreen";
import AboutScreen from "./components/screens/AboutScreen";
import ErrorScreen from "./components/screens/ErrorScreen";
import FAQScreen from "./components/screens/FAQScreen";
import ChapterScreen from "./components/screens/ChapterScreen";
import AiScreen from "./components/screens/AiScreen";



const App = () => {

    return (
        <div className="app">
            <Navbar/>
            <Switch>
                <Route exact path="/" component={HomeScreen} />
                <Route exact path="/about" component={AboutScreen} />
                {/*<PrivateRoute exact path="/summaries" component={PrivateScreen} /> */}
                <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPasswordScreen}
                />
                <Route
                    exact
                    path="/passwordreset/:resetToken"
                    component={ResetPasswordScreen}
                />
                <Route exact path="/search" component={SearchResults}/>
                {/*<PrivateRoute  exact path="/:chapterId/comments" component={ChapterScreen} />*/}
                <Route exact path="/bookmarks" component={BookmarksScreen}/>
                <Route  exact path="/:chapterId/comments" component={ChapterScreen} />
                <Route exact path="/faq" component={FAQScreen}/>
                <Route exact path="/pricing" component={PricingScreen}/>
                <Route exact path="/profile" component={ProfileScreen}/>
                <Route exact path="/ai" component={AiScreen}/>
                <Route path="*" component={ErrorScreen}/>
            </Switch>
            <Footer/>
        </div>
    );
};

export default App;