import React from 'react';
import { Grid, Typography, Button, Box, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import "./FAQScreen.css";
import useMediaQuery from '@mui/material/useMediaQuery';

const FAQScreen = () => {
  const history = useHistory();

  const applyHandler = () => {
    window.open("https://bit.ly/EZread");
  }

  const textbookRequestHandler = () => {
    window.open("https://forms.gle/ovf8HguL1knEXVfk8");
  }

  const contactHandler = () => {
    window.open("https://forms.gle/b5Zj51kKFeQcLbHq7");
  }

  const minWidth850 = useMediaQuery('(min-width:850px)');

  return (
    <>
      { minWidth850 ? 
      <>
      <Stack display="flex" 
            alignItems="center"
            justifyContent="center"
            sx={{mt: 2, color: 'common.white'}}>
                
            <Typography underline className="desc" 
            sx={{fontFamily: "Rubik", fontSize:50, fontWeight: "900", color:"#fff,"}}>
            Frequently Asked Questions
            </Typography>
        </Stack>
        <Box display="flex" 
            alignItems="center"
            justifyContent="center"
            mx="12vw"
            mt="40px"
            mb="20px"
            sx={{ color: 'common.white', fontSize: '15px'}}>
              <h3> 
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    What is EZread?
                </Typography>      
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    EZread is a hub for easy-to-read textbook summaries written by vetted scholars to help you study for your classes with high quality notes.
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    Who writes EZread summaries?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    We gather our textbook summaries through vetted writers–like graduate students, PhD candidates, and scholars–who have already mastered the material that they summarize and know what students need to know to learn the content.
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    How does EZread gather their writers and how can I become one?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    Our criteria for our writers is straightforward: they’re highly-qualified within their subject area and they can effectively distill information in an easy-to-understand manner. If you’re interested in joining our team, please click here to <Link underline="hover" onClick={applyHandler} sx= {{'&:hover': {cursor:"pointer"}}}>apply</Link>.
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    What should I do if EZread does not have my textbook?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    Our team is continually working to add more textbooks to our collection, so feel free to fill out this <Link underline="hover" onClick={textbookRequestHandler} sx= {{'&:hover': {cursor:"pointer"}}}>form</Link> with information regarding your desired textbook. We make sure to pay due diligence to contain correct and essential information in our summaries, so it may take us a few months.
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    How can I cite EZread?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    To cite EZread, write “EZread Writers” in place of authors and use “2022” as the publication date. Below are some examples:
                <br />
                    <div class="indent1">
                        MLA citation:
                    </div>
                    <div class="indent2">
                        EZread Writers. “EZread Summary Title.” EZread.io, EZread LLC, 2022, URL.
                    </div>
                    <div class="indent1">
                        APA citation:
                    </div>
                    <div class="indent2">
                        EZread Writers. (2022). EZread Summary Title. Retrieved from URL.
                    </div>
                    <div class="indent1">
                        Chicago Manual Style Citation:
                    </div>
                    <div class="indent2"> 
                        EZread Writers. “EZread Summary Title.” EZread.io. 2022. Month,Year of date accessed* URL.
                    </div>
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    How can I suggest changes for a summary?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    We try our best to create the highest quality summaries that we can, but in the world of academia, it’s impossible to be perfect. If you notice any incorrect or missing information, please leave a review on the chapter summary with a description of the error you noticed.
                </Typography>
                <br />   
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:25, fontWeight: "600", color:"#fff"}}>
                    Any questions we didn't answer?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:20, fontWeight: "500", color:"#fff"}}>
                    Feel free to <Link underline="hover" onClick={contactHandler} sx= {{'&:hover': {cursor:"pointer"}}}>contact</Link> us.
                </Typography>
              </h3> 
        </Box>
      </> : <>
      <Stack display="flex" 
            alignItems="center"
            justifyContent="center"
            sx={{mt: 2}}>
                
            <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:34, fontWeight: "900", color:"#fff"}}>
             Frequently Asked Questions
            </Typography>
        </Stack>
        <Box display="flex" 
            alignItems="center"
            justifyContent="left"
            mx="8vw"
            mt="30px"
            mb="15px"
            sx={{ color: 'common.white', fontSize: '15px'}}>
            <h3>
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    What is EZread?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    EZread is a hub for easy-to-read textbook summaries written by vetted scholars to help you study for your classes with high quality notes.
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    Who writes EZread summaries?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    We gather our textbook summaries through vetted writers–like graduate students, PhD candidates, and scholars–who have already mastered the material that they summarize and know what students need to know to learn the content.
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    How does EZread gather their writers and how can I become one?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    Our criteria for our writers is straightforward: they’re highly-qualified within their subject area and they can effectively distill information in an easy-to-understand manner. If you’re interested in joining our team, please click here to <Link underline="hover" onClick={applyHandler} sx= {{'&:hover': {cursor:"pointer"}}}>apply</Link>.
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    What should I do if EZread does not have my textbook?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    Our team is continually working to add more textbooks to our collection, so feel free to fill out this <Link underline="hover" onClick={textbookRequestHandler} sx= {{'&:hover': {cursor:"pointer"}}}>form</Link> with information regarding your desired textbook. We make sure to pay due diligence to contain correct and essential information in our summaries, so it may take us a few months.
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    How can I cite EZread?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    To cite EZread, write “EZread Writers” in place of authors and use “2022” as the publication date. Below are some examples:
                <br />
                    <div class="indent1">
                        MLA citation:
                    </div>
                    <div class="indent2">
                        EZread Writers. “EZread Summary Title.” EZread.io, EZread LLC, 2022, URL.
                    </div>
                    <div class="indent1">
                        APA citation:
                    </div>
                    <div class="indent2">
                        EZread Writers. (2022). EZread Summary Title. Retrieved from URL.
                    </div>
                    <div class="indent1">
                        Chicago Manual Style Citation:
                    </div>
                    <div class="indent2"> 
                        EZread Writers. “EZread Summary Title.” EZread.io. 2022. Month,Year of date accessed* URL.
                    </div>
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    How can I suggest changes for a summary?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    We try our best to create the highest quality summaries that we can, but in the world of academia, it’s impossible to be perfect. If you notice any incorrect or missing information, please leave a review on the chapter summary with a description of the error you noticed.
                </Typography>
                <br />
                <Typography className="desc" sx={{textDecoration: "underline",fontFamily: "Rubik", fontSize:20, fontWeight: "700", color:"#fff"}}>
                    Any questions we didn't answer?
                </Typography>           
                <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:15, fontWeight: "500", color:"#fff"}}>
                    Feel free to <Link underline="hover" onClick={contactHandler} sx= {{'&:hover': {cursor:"pointer"}}}>contact</Link> us.
                </Typography>
            </h3>
        </Box>
      </>}
        
    </>
  );
};

export default FAQScreen;
