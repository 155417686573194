import React, { useState } from 'react';
import "./FAQScreen.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link, Button, Divider, Typography, Stack, CardHeader, Alert, Collapse} from '@mui/material';

const PricingScreen = () => {
  const [openBasicAlert, setOpenBasicAlert] = useState(false);
  const [openBasicSubAlert, setOpenBasicSubAlert] = useState(false);//already have basic sub
  const [openPremiumAlert, setOpenPremiumAlert] = useState(false);
  const [openPremiumSubAlert, setOpenPremiumSubAlert] = useState(false);//already have premium sub
  const [openPremSubAlert, setOpenPremSubAlert] = useState(false);//already have premium sub (other card)

  const basicHandler = async (e) => {
    e.preventDefault();
    try {
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data.accessToken) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data.accessToken}`
                },
            };
            const usr = await axios.get(`/api/auth/user`, config);
            if (!usr.data.subscription) {
                const session = await axios.post('/api/stripe/checkout', {'priceId': "price_1M3SfYLcLaskqiyHhTljc2u4", 'userId': usr.data._id, 'sub': "basic"});
                window.open(session.data.url, "_self")
            } else if (usr.data.subscription === "basic") {
                setOpenBasicSubAlert(true);
            } else if (usr.data.subscription === "premium") {
                if (usr.data.customerId) {
                    const portalSession = await axios.post('/api/stripe/customer-portal', {'customerId': usr.data.customerId,});
                    window.open(portalSession.data.url, "_self")
                } else {
                    setOpenPremiumSubAlert(true);
                }
            }
            
        } else {
            setOpenBasicAlert(true);
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }

  const premiumHandler = async (e) => {
    e.preventDefault();
    try {
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data.accessToken) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data.accessToken}`
                },
            };
            const usr = await axios.get(`/api/auth/user`, config);
            
            if (usr.data.subscription === "premium") {
                setOpenPremSubAlert(true);
            } else if (usr.data.subscription === "basic") {
                if (usr.data.customerId) {
                    const portalSession = await axios.post('/api/stripe/customer-portal', {'customerId': usr.data.customerId,});
                    window.open(portalSession.data.url, "_self")
                }
            } else {
                const session = await axios.post('/api/stripe/checkout', {'priceId': "price_1M1wuvLcLaskqiyHagl5YXkb", 'userId': usr.data._id, 'sub': "premium"});
                window.open(session.data.url, "_self")
            }
            
        } else {
            setOpenPremiumAlert(true);
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }


  const minWidth850 = useMediaQuery('(min-width:850px)');
  window.scrollTo(0, 0);

  return (
    <Stack>
        {minWidth850 ? 
        <>
        <Typography fontWeight="bold" fontSize="50px" color="#fff" sx={{mt:"7vh", display: 'flex', justifyContent: 'center',}}>
            Explore EZread Pricing Plans.
        </Typography>
        <Typography fontSize="17px" color="#fff" sx={{mt:"2vh", display: 'flex', justifyContent: 'center'}}>
            Choose between our Basic and Premium Subscriptions, and unlock access to our
        </Typography>
        <Typography fontSize="17px" color="#fff" sx={{ display: 'flex', justifyContent: 'center'}}>
            high-quality and and easy-to-read textbook chapter summaries!
        </Typography>
        
        <Stack direction="row" spacing={"7vw"} my="5vh" sx={{display: 'flex', justifyContent: 'center',}}>
            <Card sx={{height:270, width:370, boxShadow:'0 3px 10px rgb(0 0 0 / 0.4)'}}>
                <CardHeader
                    disableTypography
                    title={<Typography ml="5px" mt="2px" variant="h5" fontWeight="bold" component="div"> Basic Plan</Typography>}
                />
                <Collapse in={openBasicAlert}>
                    <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenBasicAlert(false);}} >
                        Please <strong>login</strong> to select a subscription plan! 
                    </Alert>
                </Collapse>
                <Collapse in={openBasicSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenBasicSubAlert(false);}} >
                            You already have a basic subscription!
                        </Alert>
                </Collapse>
                <Collapse in={openPremiumSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremiumSubAlert(false);}} >
                            You already have a premium subscription!
                        </Alert>
                </Collapse>
                <CardContent sx={{pt:0}}>
                    <Box ml="5px">
                        <Typography fontSize="20px">
                        <b>$4.99/month</b>
                        </Typography>
                        <Typography fontSize="16px" mt="8px" mb="2px">
                        Unlock a single textbook's worth of chapter summaries. 
                        </Typography>
                        <Typography fontSize="14px" mb="22px">
                        <em>* Cannot purchase multiple Basic Plans</em>
                        </Typography>
                        <Divider color="#fff"></Divider>
                        
                        <form onSubmit={basicHandler}>
                            {/* <input type="hidden" id="priceId" name="priceId" value="price_1LvyddLcLaskqiyHckOgxLxr" /> */ }
                            <Button type="submit" fullWidth size="large" sx={{mt:"15px", textTransform:"none", float:"right"}} variant="contained">
                                Begin Free Trial
                            </Button>
                        </form>
                    </Box>
                </CardContent>
            </Card>

            <Card sx={{height:270, width:370, boxShadow:'0 3px 10px rgb(0 0 0 / 0.4)'}}>
                <CardHeader
                    disableTypography
                    title={<Typography variant="h5" fontWeight="bold" component="div"> Premium Plan</Typography>}
                    action={<Button variant="contained" size="small" color="secondary" disableRipple
                    sx={{borderRadius: 5, fontWeight:"bold",
                     textTransform: "none", fontSize:12, '&:hover': {cursor:"default"}, boxShadow: "0 0 0", '&:focus': "none"}}>
                    Best deal
                    </Button>}
                />
                <Collapse in={openPremiumAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremiumAlert(false);}} >
                            Please <strong>login</strong> to select a subscription plan! 
                        </Alert>
                </Collapse>
                <Collapse in={openPremSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremSubAlert(false);}} >
                            You already have a premium subscription!
                        </Alert>
                </Collapse>
                <CardContent sx={{pt:0}}>
                    <Box ml="5px">
                        {/*<Typography gutterBottom variant="h5" color="#fff" fontWeight="bold" component="div">{title}</Typography>*/}
                        <Typography fontSize="20px">
                        <b>$11.99/month</b>
                        </Typography>
                        <Typography fontSize="16px" mt="8px">
                        Unlock access to all of our EZread textbook chapter summaries!
                        </Typography>
                        <Typography fontSize="16px" mb="23px">
                        <i>Receive top priority for textbook requests.</i>
                        </Typography>
                        <Divider></Divider>
                        <Button onClick={premiumHandler} fullWidth color="secondary" size="large" sx={{mt:"15px", textTransform:"none", float:"right"}} variant="contained">Begin Free Trial</Button>
                    </Box>
                </CardContent>
            </Card>
        </Stack>
        <Typography sx={{display:"flex", justifyContent:"center", mb:"2vh", fontWeight:"700", fontSize: 28, color:"#fff"}}> 
            Pricing starting as low as $4.99, cancel anytime.
        </Typography>
        <Typography sx={{display:"flex", justifyContent:"center", mt:"3vh", fontWeight:"600", fontSize: 38, color:"#fff"}}> 
                Student Testimonials:
        </Typography>
        
        <Stack direction="row" spacing={"3vw"} mt="3vh" sx={{display: 'flex', justifyContent: 'center', mb:"4vw"}}>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src="https://i.imgur.com/gvZl08u.jpg" alt="james" style={{width:"290px", height:"220px", borderRadius: 0}} />
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                        <Typography fontSize="16px" fontWeight="bold" color="white">
                        "Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                        </Typography>
                        <Typography fontSize="15px" mt="15px" color="white">
                        - James, Stanford '23 
                        </Typography>
                </Stack>
            </Card>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src={`${process.env.PUBLIC_URL}/natalia.webp`}  alt="natalia" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                    <Typography fontSize="16px" fontWeight="bold" color="white">
                    "With EZread summaries, I'm able to get a good night's rest before my exam
                    instead of stressing over self-studying my textbooks."
                    </Typography>
                    <Typography fontSize="15px" mt="15px" color="white">
                    - Natalia, Emory '25 
                    </Typography>
                </Stack>
            </Card>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src={`${process.env.PUBLIC_URL}/rohan.webp`} alt="rohan" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                    <Typography fontSize="16px" fontWeight="bold" color="white">
                    "I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                    </Typography>
                    <Typography fontSize="15px" mt="15px" color="white">
                    - Rohan, Vanderbilt '24 
                    </Typography>
                </Stack>
            </Card>
        </Stack>
        <Typography fontSize="17px" color="#fff" sx={{mt:"2vh", mb:"10vh", display: 'flex', justifyContent: 'center'}}>
            <b>Questions?</b> &nbsp;Email us at&nbsp;
            <Link underline="hover" href="mailto:contact@ezread.io" sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><u>contact@ezread.io</u></Link>&nbsp;or click the&nbsp;
            <Link underline="hover" onClick={() => {window.open("https://forms.gle/b5Zj51kKFeQcLbHq7");}} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><u>Contact</u></Link>&nbsp;button in the navbar!
        </Typography>
        </>
        
        : 
        <>
        <Typography fontWeight="bold" fontSize="28px" color="#fff" sx={{mt:"5vh", display: 'flex', justifyContent: 'center',}}>
            Explore EZread Pricing Plans.
        </Typography>
        <Typography fontSize="14px" color="#fff" sx={{mt:"2vh", display: 'flex', justifyContent: 'center'}}>
            Choose between our Basic and Premium Subscriptions, 
        </Typography>
        <Typography fontSize="14px" color="#fff" sx={{ display: 'flex', justifyContent: 'center'}}>
            and unlock exclusive access to our high-quality and
        </Typography>
        <Typography fontSize="14px" color="#fff" sx={{ display: 'flex', justifyContent: 'center'}}>
            easy-to-read textbook chapter summaries!
        </Typography>
        
        <Stack spacing={"5vh"} mt="5vh" mb="3vh" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card sx={{height:240, width:350, boxShadow:'0 3px 10px rgb(0 0 0 / 0.4)'}}>
                <CardHeader sx={{pb:"8px"}}
                    disableTypography
                    title={<Typography ml="5px" variant="h6" fontWeight="bold" component="div"> Basic Plan</Typography>}
                />
                <Collapse in={openBasicAlert}>
                    <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenBasicAlert(false);}} >
                        Please <strong>login</strong> to select a subscription plan! 
                    </Alert>
                </Collapse>
                <Collapse in={openBasicSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenBasicSubAlert(false);}} >
                            You already have a basic subscription!
                        </Alert>
                </Collapse>
                <Collapse in={openPremiumSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremiumSubAlert(false);}} >
                            You already have a premium subscription!
                        </Alert>
                </Collapse>
                <CardContent sx={{pt:0}}>
                    <Box ml="5px">
                        <Typography fontSize="17px">
                        <b>$4.99/month</b>, cancel anytime.
                        </Typography>
                        <Typography fontSize="14px" mt="8px" mb="2px">
                        Unlock a single textbook's worth of chapter summaries. 
                        </Typography>
                        <Typography fontSize="12px" mb="18px" >
                        <em>* Cannot purchase multiple Basic Plans</em>
                        </Typography>
                        <Divider></Divider>
                        
                        <form onSubmit={basicHandler}>
                            {/* <input type="hidden" id="priceId" name="priceId" value="price_1LvyddLcLaskqiyHckOgxLxr" /> */}
                            <Button type="submit" fullWidth sx={{mt:"15px", textTransform:"none", float:"right"}} variant="contained">
                                Begin Free Trial
                            </Button>
                        </form>
                    </Box>
                </CardContent>
            </Card>

            <Card sx={{height:240, width:350, boxShadow:'0 3px 10px rgb(0 0 0 / 0.4)'}}>
                <CardHeader
                    sx={{pb:"8px"}}
                    disableTypography
                    title={<Typography variant="h6" fontWeight="bold" component="div"> Premium Plan</Typography>}
                    action={<Button variant="contained" size="small" color="secondary" disableRipple
                    sx={{borderRadius: 5, fontWeight:"bold",
                     textTransform: "none", fontSize:12, '&:hover': {cursor:"default"}, boxShadow: "0 0 0", '&:focus': "none"}}>
                    Best deal
                    </Button>}
                />
                <Collapse in={openPremiumAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremiumAlert(false);}} >
                            Please <strong>login</strong> to select a subscription plan! 
                        </Alert>
                </Collapse>
                <Collapse in={openPremSubAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenPremSubAlert(false);}} >
                            You already have a premium subscription!
                        </Alert>
                </Collapse>
                <CardContent sx={{pt:0}}>
                    <Box ml="5px">
                        {/*<Typography gutterBottom variant="h5" color="#fff" fontWeight="bold" component="div">{title}</Typography>*/}
                        <Typography fontSize="17px">
                        <b>$11.99/month</b>, cancel anytime.
                        </Typography>
                        <Typography fontSize="14px" mt="8px">
                        Unlock access to all of our EZread textbook chapter summaries!
                        </Typography>
                        <Typography fontSize="14px" mb="23px">
                        <i>Receive top priority for textbook requests.</i>
                        </Typography>
                        <Divider></Divider>
                        <Button 
                        onClick={premiumHandler} fullWidth color="secondary" sx={{mt:"15px", textTransform:"none", float:"right"}} variant="contained">
                            Get EZread Premium
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Stack>
        <Typography sx={{display:"flex", justifyContent:"center", mt:"3vh", fontWeight:"600", fontSize: 32, color:"#fff"}}> 
                Student Testimonials:
        </Typography>
        <Stack spacing={"3vh"} mt="3vh" sx={{display: 'flex', alignItems: 'center', mb:"4vw"}}>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src="https://i.imgur.com/gvZl08u.jpg" alt="james" style={{width:"290px", height:"220px", borderRadius: 0}} />
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                        <Typography fontSize="16px" fontWeight="bold" color="white">
                        "Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                        </Typography>
                        <Typography fontSize="15px" mt="15px" color="white">
                        - James, Stanford '23 
                        </Typography>
                </Stack>
            </Card>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src={`${process.env.PUBLIC_URL}/natalia.webp`} alt="natalia" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                    <Typography fontSize="16px" fontWeight="bold" color="white">
                    "With EZread summaries, I'm able to get a good night's rest before my exam
                    instead of stressing over self-studying my textbooks."
                    </Typography>
                    <Typography fontSize="15px" mt="15px" color="white">
                    - Natalia, Emory '25 
                    </Typography>
                </Stack>
            </Card>
            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                <img src={`${process.env.PUBLIC_URL}/rohan.webp`} alt="rohan" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                    <Typography fontSize="16px" fontWeight="bold" color="white">
                    "I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                    </Typography>
                    <Typography fontSize="15px" mt="15px" color="white">
                    - Rohan, Vanderbilt '24 
                    </Typography>
                </Stack>
            </Card>
        </Stack>
        <Typography fontSize="17px" color="#fff" sx={{mt:"4vh", display: 'flex', justifyContent: 'center'}}>
            <b>Questions?</b>
        </Typography>
        <Typography fontSize="14px" color="#fff" sx={{mt: "1vh", display: 'flex', justifyContent: 'center'}}>
            Email us at&nbsp;
            <Link underline="hover" href="mailto:contact@ezread.io" sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><u>contact@ezread.io</u></Link>
            &nbsp;or click the
        </Typography>
        <Typography fontSize="14px" color="#fff" sx={{mb:"10vh", display: 'flex', justifyContent: 'center'}}>
            <Link underline="hover" onClick={() => {window.open("https://forms.gle/b5Zj51kKFeQcLbHq7");}} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><u>Contact</u></Link>&nbsp;button in the navbar!
        </Typography>
        </>
        }

        
    </Stack>
  );
};

export default PricingScreen;
