import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Textbooks from '../textbooks/textbooks.js'
import Divider from '@mui/material/Divider';
import axios from 'axios';
//import './BookmarksScreen.css'
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Collapse} from '@mui/material';
import LoadingSpinner from '../common/LoadingSpinner';

const Bookmarks = (Component) => {
    const history = useHistory();
    const search = useLocation().search;    
    const [textbooks, setTextbooks] = useState([[]]);

    const [sortBy, setSortBy] = useState(new URLSearchParams(search).get('sortBy'));
    if (!sortBy || sortBy === "null") {
        setSortBy("Default");
    }
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [error, setError] = useState("");
    const handleClose = () => setOpen(false);
    const [pageLoading, setPageLoading] = useState(true);

    
    const handleChange = (event, newPage)  => {
        setPage(newPage);
        window.scrollTo(0, 0)
        //clearSearch();
    };

    const notMobile = useMediaQuery('(min-width:1000px)');

    useEffect(() => {
        const setVals = async () => {
            try {
                const res = await axios.get('/api/auth/refresh-token');
                if (res.data.accessToken) {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${res.data.accessToken}`
                        },
                    };
                    const text = await axios.get(`/api/textbooks/bookmarks`, config);
                   // console.log(text)
                    setTextbooks(text.data)
                    setPageLoading(false);
                } else {
                    history.push("");
                }

            } catch (err) {
                console.log(err)
            }
        };
        setVals();
    }, []);

  return (
    <Box>
        {pageLoading ? <LoadingSpinner text={'Loading summaries...'} /> : <>
        <Collapse in={openAlert}>
            <Alert severity="warning" variant="filled" onClose={() => {setOpenAlert(false);}} >
                Please <strong>login</strong> to bookmark a textbook! 
            </Alert>
        </Collapse>
        
        <Typography fontSize="32px" fontWeight="bold" sx={{ p: 0, ml: "3vw", my:"3vh", color:"#fff"}}>Bookmarks:</Typography>
        {textbooks.results && <Textbooks textbooks={textbooks}/>}
        <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center",
            '& .MuiPaginationItem-root': {color:"royalBlue", borderColor: "#9c9a9a"},
        }} >
            <Pagination
                onChange={handleChange}
                page={page}
                color="primary" 
                variant = "outlined"
                shape="rounded" 
                count={textbooks.pageCount}
                defaultPage={1}
                sx={{pb:1}}
                />
            {textbooks.pageCount > 0 && <Typography style={{fontWeight:100, fontSize:12, color: "#fff"}}>Showing {page} out of {textbooks.pageCount} pages</Typography> }
        </Stack>
        </>}
    </Box>
  );
};

export default Bookmarks;