import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Textbooks from '../textbooks/textbooks.js'
import Divider from '@mui/material/Divider';
import axios from 'axios';
import './SearchResults.css'
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import './SearchBar.css'
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Collapse} from '@mui/material';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadingSpinner from '../common/LoadingSpinner';
//import Ad from '../ads/Ad';

const SearchResults = () => {
    const search = useLocation().search;    
    const [textbooks, setTextbooks] = useState([[]]);
    const [types, setTypes] = useState([]);
    const [years, setYears] = useState([]);
    const [editions, setEditions] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [publishers, setPublishers] = useState([]);

    //const [searchInput, setSearchInput] = useState('');
    const history = useHistory();
    const query = new URLSearchParams(search).get('query');
    //const [query, setQuery] = useState(new URLSearchParams(search).get('query'))
    
    const [sortBy, setSortBy] = useState(new URLSearchParams(search).get('sortBy'));
    if (!sortBy || sortBy === "null") {
        setSortBy("Default");
    }
    const subj = new URLSearchParams(search).get('subject');
    const [subject, setSubject] = useState(subj);
    const [type, setType] = useState("All");
    const [publisher, setPublisher] = useState(new URLSearchParams(search).get('publisher'));
    const [edition, setEdition] = useState(new URLSearchParams(search).get('edition'));
    const [year, setYear] = useState(new URLSearchParams(search).get('year'));
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    //const [error, setError] = useState("");
    /*const [isbnError, setIsbnError] = useState("");
    const [isbn10, setIsbn10] = useState("");
    const [isbn13, setIsbn13] = useState("");*/
    const [subjectModal, setSubjectModal] = useState(false);
    const [pubModal, setPubModal] = useState(false);
    const [editionModal, setEditionModal] = useState(false);
    const [yearModal, setYearModal] = useState(false);
    const [openFilters, setOpenFilters] = useState(false)
    const handleClose = () => setOpen(false);
    const handleFiltersOpen = () => setOpenFilters(true)
    const handleFiltersClose = () => setOpenFilters(false)
    const [pageLoading, setPageLoading] = useState(true);

    const buttonHandler = async () => {
        /*
        try {
            await axios.get(`/api/auth/refresh-token`).then(res => checkToken(res.data)).catch(error => console.log(error));
        } catch(error) {
            console.log(error);
        }*/
        window.open("https://forms.gle/ovf8HguL1knEXVfk8");
    }
    //old modal to request textbooks
    /*
    const checkToken = (token) => {
        if(token.accessToken) {
            setOpen(true);
        } else{
            setError("Please login in order to request");
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    };*/
    /*
    const requestHandler = async (e) => {
        if(isbn10 || isbn13) {
            e.preventDefault();
            try {
                const {data} = await axios.get(`/api/auth/refresh-token`);

                await axios.post(
                    "/api/textbookRequests",
                    { isbn10, isbn13 },
                    {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.accessToken}`}}
                );
                setOpen(false);
            } catch (error) {
                setIsbnError(error.response.data.message);
                console.log(error.response.data.message)
                setTimeout(() => {
                    setIsbnError("");
                }, 3000);
            }
        }
        
    };*/

    const clearFilters = () => {
        setSubject('');
        setType('All');
        setPublisher('');
        setEdition('');
        setYear('')
    }

    /*const clearSearch = () => {
        //if (isMobile) {
          //setSearchOpen(false);
        //}
        ////setSearchInput('');
    };*/

    const handleSubject = (e) => {
        setPage(1);
        setSubject(e.target.value);
        setType('All');
        //clearSearch();
    }

    const handlePublisher = (e) => {
        setPublisher(e.target.value);
        //clearSearch();
        setPage(1);
    };

    const handleEdition = (e) => {
        setEdition(e.target.value);
        //clearSearch();
        setPage(1);
    };

    const handleYear = (e) => {
        setYear(e.target.value);
        //clearSearch();
        setPage(1);
    };

    const handleChange = (event, newPage)  => {
        setPage(newPage);
        window.scrollTo(0, 0)
        //clearSearch();
    };


    /*const handleSearch = (e) => {
        e.preventDefault();
        if (searchInput === ''){
            return;
        } else {
            clearFilters();
            setSortBy('Default');
        }
        //query=e.target.value;
        history.push(`/search/${searchInput}`);
    };*/

    useEffect(() => {
        const getSearchResults = async () => {
        try {
            setPageLoading(true);
            await axios.get(`/api/textbooks/subjects`).then(res => setSubjects(res.data)).catch(error => console.log(error));
            await axios.get(`/api/textbooks/publishers`).then(res => setPublishers(res.data)).catch(error => console.log(error));
            await axios.get(`/api/textbooks/editions`).then(res => setEditions(res.data)).catch(error => console.log(error));
            await axios.get(`/api/textbooks/years`).then(res => setYears(res.data)).catch(error => console.log(error));
            await axios.get(`/api/textbooks/types/?query=${subject}`).then(res => setTypes([res.data])).catch(error => console.log(error));
            if (type === "All") {
                axios.get(`/api/textbooks/search/?query=${query}&subject=${subject}&publisher=${publisher}&edition=${edition}&year=${year}&page=${page}&sortBy=${sortBy}`)
                    .then(res => setTextbooks(res.data)).catch(error => console.log(error));
                history.push(`/search/?query=${query}&subject=${subject}&publisher=${publisher}&edition=${edition}&year=${year}&page=${page}&sortBy=${sortBy}`);
            } else {
                axios.get(`/api/textbooks/search/?query=${query}&subject=${subject}&type=${type}&publisher=${publisher}&edition=${edition}&year=${year}&page=${page}&sortBy=${sortBy}`)
                    .then(res => setTextbooks(res.data)).catch(error => console.log(error));
                history.push(`/search/?query=${query}&subject=${subject}&type=${type}&publisher=${publisher}&edition=${edition}&year=${year}&page=${page}&sortBy=${sortBy}`);
            }
            setPageLoading(false);
            
        } catch (err) {
            console.log(err)
        }
    };
    getSearchResults();
    }, [query, sortBy, subject, type, publisher, edition, year, page, history]);

    const notMobile = useMediaQuery('(min-width:1000px)');

  return (
    <div class="grid">
        <search id="pageSearch">
            { notMobile ? 
            <Stack direction="row" spacing={20}>
            <Box sx={{width: "76%"}}>
                {(!publisher && !edition && !year && !subject && query !== (years+"") && !pageLoading) ?
                    <p style={{"margin-top": "22px"}}>Showing results for: "{query}"</p> : <p style={{"margin-top": "22px"}}>Showing results for: </p>
                }
            </Box>
            <TextField
                focused="true"
                size="small"
                variant="outlined"
                id="outlined-select"
                select
                label="Sort"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                style={{"margin-top": "18px"}}
                sx={{
                    "& .MuiFormHelperText-root": {color:"primary"},
                    "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                    "& .MuiSelect-icon": {color:"#fff"},
                    "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                }}
            >
                <MenuItem value="Default">Default</MenuItem>
                <MenuItem value="Title (A->Z)">Title (A→Z)</MenuItem>
                <MenuItem value="Title (Z->A)">Title (Z→A)</MenuItem>
                <MenuItem value="Edition (Low->High)">Edition (Low→High)</MenuItem>
                <MenuItem value="Edition (High->Low)">Edition (High→Low)</MenuItem>
            </TextField>
        </Stack> : 
        <Stack direction="row" spacing={0}>
            <Box sx={{width: "76%"}}>
                {(!publisher && !edition && !year && !subject && query !== (years+"") && !pageLoading) ?
                    <p style={{"margin-top": "22px"}}>Showing results for: "{query}"</p> : <p style={{"margin-top": "22px"}}>Showing results for: </p>
                }
            </Box>
            <TextField
                focused="true"
                size="small"
                variant="outlined"
                id="outlined-select"
                select
                label="Sort"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                style={{"margin-top": "18px"}}
                sx={{
                    "& .MuiFormHelperText-root": {color:"primary"},
                    "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                    "& .MuiSelect-icon": {color:"#fff"},
                    "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                }}
            >
                <MenuItem value="Default">Default</MenuItem>
                <MenuItem value="Title (A->Z)">Title (A→Z)</MenuItem>
                <MenuItem value="Title (Z->A)">Title (Z→A)</MenuItem>
                <MenuItem value="Edition (Low->High)">Edition (Low→High)</MenuItem>
                <MenuItem value="Edition (High->Low)">Edition (High→Low)</MenuItem>
            </TextField>
        </Stack>
        }
            
        </search>
        <filters id="pageFilters">
            { notMobile ? 
            <>
            <Box>
                <label style={{fontSize:24, fontWeight:600, marginLeft:3}}> Filters </label>
                <Link underline="hover" onClick={clearFilters} sx={{marginLeft:"18px", fontSize:14, color:"#c4c4c4", '&:hover': {cursor:"pointer"}}}>Clear all</Link>
            </Box>
            <Divider variant="fullWidth" color="#374e69" sx={{my:"8px"}}/>
            <Box
                sx={{
                    "& .MuiTextField-root": {},
                    "& .MuiFormHelperText-root": {color:"#fff"},
                    "& .MuiSelect-select": {color: "#fff", borderColor: "#fff"},
                    "& .MuiSelect-icon": {color:"#fff"},
                }}
                >
                <FormControl>
                    <Stack direction="row">
                        <Typography sx={{fontSize:17, fontWeight:"bold", color:"#fff", mt:1, mb:0.5}}> Subject </Typography>
                        <Link underline="hover" onClick={() => setSubject('')}
                        sx={{ml:2, fontSize:13, color:"#c4c4c4", mt:1.5, mb:0.5, '&:hover': {cursor:"pointer"}}}>
                            Clear
                        </Link>
                    </Stack>
                   
                    <RadioGroup
                    aria-labelledby="subjects"
                    name="subject"
                    value={subject}
                    onChange={handleSubject}
                    >
                        <FormControlLabel value="Biology" label="Biology" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="Chemistry" label="Chemistry" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="Communications" label="Communications" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                    </RadioGroup>
                    <Link onClick={() => setSubjectModal(true)} underline="hover" sx={{fontSize:15, fontWeight:"bold", color:"primary", mt:1, mb:0.7, '&:hover': {cursor:"pointer"}}}> 
                        See all 
                    </Link>
                    <TextField
                        focused="true"
                        size="small"
                        variant="outlined"
                        id="outlined-select"
                        select
                        label="Type"
                        value={type}
                        onChange={(e) => {setType(e.target.value); setPage(1);} }
                        //helperText="Select a subject type"
                        margin="normal"
                        sx={{
                            "& .MuiFormHelperText-root": {color:"primary"},
                            "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                            "& .MuiSelect-icon": {color:"#fff"},
                            "& .MuiMenuItem-root": {borderColor:"#fff"},
                        }}
                    >
                        <MenuItem value="All">
                            All
                        </MenuItem>
                        {types.map(t => (
                            <MenuItem value={t}>{t}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Box>
            <Divider variant="fullWidth" color="#374e69" sx={{my:"8px"}}/>
            <Box
                sx={{
                    "& .MuiFormHelperText-root": {color:"#fff"},
                    "& .MuiSelect-select": {color: "#fff", borderColor: "#fff"},
                    "& .MuiSelect-icon": {color:"#fff"},
                }}
                >
                <FormControl>
                    <Stack direction="row">
                        <Typography sx={{fontSize:17, fontWeight:"bold", color:"#fff", mt:1, mb:0.5}}> Publisher </Typography>
                        <Link underline="hover" onClick={() => setPublisher('')}
                        sx={{ml:2, fontSize:13, color:"#c4c4c4", mt:1.5, mb:0.5, '&:hover': {cursor:"pointer"}}}>
                            Clear
                        </Link>
                    </Stack>
                    <RadioGroup
                    aria-labelledby="publishers"
                    name="publishers"
                    value={publisher}
                    onChange={handlePublisher}
                    >
                        <FormControlLabel value="McGraw-Hill Education" label="McGraw-Hill Education" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="Pearson" label="Pearson" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="W. W. Norton and Company" label="W. W. Norton & Company" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        
                    </RadioGroup>
                    <Link onClick={() => setPubModal(true)} underline="hover" sx={{fontSize:15, fontWeight:"bold", color:"#primary", mt:1, mb:0.7, '&:hover': {cursor:"pointer"}}}> 
                        See all 
                    </Link>
                </FormControl>
            </Box>
            <Divider variant="fullWidth" color="#374e69" sx={{my:"8px"}}/>
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": {},
                    "& .MuiFormHelperText-root": {color:"#fff"},
                    "& .MuiSelect-select": {color: "#fff"},
                    "& .MuiSelect-icon": {color:"#fff"}
                }}
            >
                <FormControl>
                    <Stack direction="row">
                        <Typography sx={{fontSize:17, fontWeight:"bold", color:"#fff", mt:1, mb:0.5}}> Edition </Typography>
                        <Link underline="hover" onClick={() => setEdition('')}
                        sx={{ml:2, fontSize:13, color:"#c4c4c4", mt:1.5, mb:0.5, '&:hover': {cursor:"pointer"}}}>
                            Clear
                        </Link>
                    </Stack>
                    <RadioGroup
                    aria-labelledby="editions"
                    name="editions"
                    value={edition}
                    onChange={handleEdition}
                    >
                        <FormControlLabel value="4th" label="4th" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="5th" label="5th" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="6th" label="6th" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                    </RadioGroup>
                    <Link onClick={() => setEditionModal(true)} underline="hover" sx={{fontSize:15, fontWeight:"bold", color:"#primary", mt:1, mb:0.7, '&:hover': {cursor:"pointer"}}}> 
                        See all 
                    </Link>
                </FormControl>
            </Box>
            <Divider variant="fullWidth" color="#374e69" sx={{my:"8px"}}/>
            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root": {},
                    "& .MuiFormHelperText-root": {color:"#fff"},
                    "& .MuiSelect-select": {color: "#fff"},
                    "& .MuiSelect-icon": {color:"#fff"}
                }}
            >
                <FormControl>
                    <Stack direction="row">
                        <Typography sx={{fontSize:17, fontWeight:"bold", color:"#fff", mt:1, mb:0.5}}> Year </Typography>
                        <Link underline="hover" onClick={() => setYear('')}
                        sx={{ml:2, fontSize:13, color:"#c4c4c4", mt:1.5, mb:0.5, '&:hover': {cursor:"pointer"}}}>
                            Clear
                        </Link>
                    </Stack>
                    <RadioGroup
                    aria-labelledby="years"
                    name="years"
                    value={year}
                    onChange={handleYear}
                    >
                        <FormControlLabel value="2013" label="2013" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="2014" label="2014" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                        <FormControlLabel value="2015" label="2015" control={<Radio size="small" sx={{color:"#fff", py:0.75}}/>} />
                    </RadioGroup>
                    <Link onClick={() => setYearModal(true)} underline="hover" sx={{fontSize:15, fontWeight:"bold", color:"#primary", mt:1, mb:0.7, '&:hover': {cursor:"pointer"}}}> 
                        See all 
                    </Link>
                </FormControl>
            </Box>
            { /* <Button fullWidth="true" variant="contained" color="primary" size="large" onClick={handleFilters}>Submit</Button> */ }
            </>
            :
            <Box>{ /* Filter thing for mobile*/ }
                <Box pb={2} sx={{alignItems:"center",}}>
                    <label style={{fontSize:22, fontWeight:550}}> Filters </label>
                    <Button variant="contained" sx={{fontWeight:"bold", mt:"-4px", ml:3, mr:2}} onClick={handleFiltersOpen}>Expand</Button>
                    <Link underline="hover" onClick={clearFilters} sx={{marginLeft:"12px", fontWeight:"bold", fontSize:15, color:"#c4c4c4", '&:hover': {cursor:"pointer"}}}>Clear all</Link>
                </Box>
                <Modal open={openFilters} onClose={handleFiltersClose} sx = {{ 
                        justifyContent: 'center',
                        overflow: "auto",
                        display: "flex",
                        marginTop:"6vh",
                        color:"white",
                        pt:"6px"
                }}>
                    <Box backgroundColor="#203754" sx={{borderRadius:2, height: "240px", p:2}}>
                    <Stack direction="row" spacing={2} pb={2} sx={{alignItems:"center"}}>
                    <Typography fontWeight="bold">Subject: </Typography>
                    <TextField
                    focused="true"
                    size="small"
                    variant="outlined"
                    select
                    label="Subject"
                    value={subject}
                    onChange={(e) => handleSubject(e)}
                    sx={{
                        "& .MuiFormHelperText-root": {color:"primary"},
                        "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                        "& .MuiSelect-icon": {color:"#fff"},
                        "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                    }}
                >
                    {subjects.map(subj => (<MenuItem value={subj}>{subj}</MenuItem>))}
                </TextField>
                <TextField
                        focused="true"
                        size="small"
                        variant="outlined"
                        select
                        label="Type"
                        value={type}
                        onChange={(e) => {setType(e.target.value); setPage(1);}}
                        //helperText="Select a subject type"
                        margin="normal"
                        sx={{
                            "& .MuiFormHelperText-root": {color:"primary"},
                            "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                            "& .MuiSelect-icon": {color:"#fff"},
                            "& .MuiMenuItem-root": {borderColor:"#fff"},
                        }}
                    >
                        <MenuItem value="All">
                            All
                        </MenuItem>
                        {types.map(t => (
                            <MenuItem value={t}>{t}</MenuItem>
                        ))}
                    </TextField>
                    <Link underline="hover" onClick={() => {setSubject(''); setType('');}}
                        sx={{ml:2, fontSize:15, fontWeight:"bold", color:"#c4c4c4", '&:hover': {cursor:"pointer"}, float:"right"}}>
                            Clear
                    </Link>
                </Stack>
                <Stack direction="row" spacing={2} pb={2} sx={{alignItems:"center"}}>
                    <Typography fontWeight="bold">Publisher: </Typography>
                    <TextField
                    focused="true"
                    size="small"
                    variant="outlined"
                    select
                    label="Publisher"
                    value={publisher}
                    onChange={(e) => handlePublisher(e)}
                    sx={{
                        "& .MuiFormHelperText-root": {color:"primary"},
                        "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                        "& .MuiSelect-icon": {color:"#fff"},
                        "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                    }}
                >
                    {publishers.map(pub => (<MenuItem value={pub}>{pub}</MenuItem>))}
                </TextField>
                <Link underline="hover" onClick={() => setPublisher('')}
                        sx={{ml:2, fontSize:15, fontWeight:"bold", color:"#c4c4c4", float:"right", '&:hover': {cursor:"pointer"}}}>
                            Clear
                </Link>
                </Stack>
                <Stack direction="row" spacing={2} pb={2} sx={{alignItems:"center"}}>
                    <Typography fontWeight="bold">Edition: </Typography>
                    <TextField
                    focused="true"
                    size="small"
                    variant="outlined"
                    select
                    label="Edition"
                    value={edition}
                    onChange={(e) => handleEdition(e)}
                    sx={{
                        "& .MuiFormHelperText-root": {color:"primary"},
                        "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                        "& .MuiSelect-icon": {color:"#fff"},
                        "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                    }}
                >
                    {editions.map(ed => (<MenuItem value={ed}>{ed}</MenuItem>))}
                    </TextField>
                    <Link underline="hover" onClick={() => setEdition('')}
                            sx={{ml:2, fontSize:15, color:"#c4c4c4",'&:hover': {cursor:"pointer"}, fontWeight:"bold"}}>
                                Clear
                    </Link>
                </Stack>
                <Stack direction="row" spacing={2} sx={{alignItems:"center"}}>
                    <Typography fontWeight="bold">Year: </Typography>
                    <TextField
                    focused="true"
                    size="small"
                    variant="outlined"
                    select
                    label="Year"
                    value={year}
                    onChange={(e) => handleYear(e)}
                    sx={{
                        "& .MuiFormHelperText-root": {color:"primary"},
                        "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                        "& .MuiSelect-icon": {color:"#fff"},
                        "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                    }}
                >
                    {years.map(yr => (<MenuItem value={yr}>{yr}</MenuItem>))}
                </TextField>
                <Link underline="hover" onClick={() => setYear('')}
                        sx={{ml:2, fontSize:15, fontWeight:"bold", color:"#c4c4c4", '&:hover': {cursor:"pointer"}}}>
                            Clear
                </Link>
                </Stack>
                    </Box>

                </Modal>
            </Box>
            }
            
        </filters>
        <textbooks id="pageTextbooks">
            {pageLoading ? <LoadingSpinner text={'Loading summaries...'} /> : <>
            <Collapse in={openAlert}>
                <Alert severity="warning" variant="filled" onClose={() => {setOpenAlert(false);}} >
                    Please <strong>login</strong> to bookmark a textbook! 
                </Alert>
            </Collapse>
            <br></br>
            {(textbooks.results && textbooks.results.length) ? null : (
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
                >
                    <h1>Can't find the textbook you're looking for?</h1>
                    <p>Fill out this form and we will add it to our collection ASAP!</p>
                    {/*<p style={{color:"red", marginTop:6, marginBottom:10}}>{error}</p>*/}
                    <Button variant="contained" onClick={buttonHandler}>Request Form</Button>
                </Box>
            )}
            <Dialog open={subjectModal} onClose={()=>setSubjectModal(false)} sx={{display:"flex", maxHeight: 300, "& .MuiDialog-paper": {color:"#fff", width:240, bgcolor:"#254468"}}}>
                <DialogTitle sx={{m: 0, p:0, ml:2, py:1}}>Subjects</DialogTitle>
                <Divider variant="fullWidth" color="#4c84c4"/>
                <List sx={{ pt: 0}}>
                    {subjects.map((subject) => (
                    <ListItem button onClick={() => {setSubject(subject); setPage(1); setSubjectModal(false);}} key={subject}>
                        <ListItemText primary={subject} />
                    </ListItem>
                    ))}
                </List>
            </Dialog>
            <Dialog open={pubModal} onClose={()=>setPubModal(false)} sx={{display:"flex", maxHeight: 300, "& .MuiDialog-paper": {color:"#fff", width:240, bgcolor:"#254468"}}}>
                <DialogTitle sx={{m: 0, p:0, ml:2, py:1}}>Publishers</DialogTitle>
                <Divider variant="fullWidth" color="#4c84c4"/>
                <List sx={{ pt: 0}}>
                    {publishers.map((publisher) => (
                    <ListItem button onClick={() => {setPublisher(publisher); setPage(1); setPubModal(false)}} key={publisher}>
                        <ListItemText primary={publisher} />
                    </ListItem>
                    ))}
                </List>
            </Dialog>
            <Dialog open={editionModal} onClose={()=>setEditionModal(false)} sx={{display:"flex", maxHeight: 300, "& .MuiDialog-paper": {color:"#fff", width:240, bgcolor:"#254468"}}}>
                <DialogTitle sx={{m: 0, p:0, ml:2, py:1}}>Editions</DialogTitle>
                <Divider variant="fullWidth" color="#4c84c4"/>
                <List sx={{ pt: 0}}>
                    {editions.map((edition) => (
                    <ListItem button onClick={() => {setEdition(edition); setPage(1); setEditionModal(false)}} key={edition}>
                        <ListItemText primary={edition} />
                    </ListItem>
                    ))}
                </List>
            </Dialog>
            <Dialog open={yearModal} onClose={()=>setYearModal(false)} sx={{display:"flex", maxHeight: 300, "& .MuiDialog-paper": {color:"#fff", width:240, bgcolor:"#254468"}}}>
                <DialogTitle sx={{m: 0, p:0, ml:2, py:1}}>Years</DialogTitle>
                <Divider variant="fullWidth" color="#4c84c4"/>
                <List sx={{ pt: 0}}>
                    {years.map((year) => (
                    <ListItem button onClick={() => {setYear(year); setPage(1); setYearModal(false)}} key={year}>
                        <ListItemText primary={year} />
                    </ListItem>
                    ))}
                </List>
            </Dialog>
            
            <Modal open={open} onClose={handleClose} style = {{ 
                        justifyContent: 'center',
                        overflow: "auto",
                        display: "flex",
                        marginTop: 300,
                        marginLeft: 290
                    }}>
                <Box tabIndex={-1} sx={{ mt: 3, p: 2}} style={{ borderRadius:5, width: 260, height: 134}} backgroundColor="#fff">
                {/*
                <form onSubmit={requestHandler}>
                    <label style={{color:"#000"}}>
                    ISBN-13: <input id="isbn13" required value={isbn13} onChange={(e) => setIsbn13(e.target.value)} style={{marginBottom:"10px"}}/>
                    </label>
                    <br></br>
                    <label style={{color:"#000", marginTop:"10px"}}>
                    ISBN-10: <input id="isbn10" value={isbn10} onChange={(e) => setIsbn10(e.target.value)} style={{marginBottom:"10px"}}/>
                    </label>
                    <br />
                    <Button size="small" fullWidth variant="contained" type="submit" color="error">
                    Submit
                    </Button>
                    <p style={{fontSize: "16px", color:"red",justifyContent: 'center',display: "flex", backgroundColor:"#fff", marginTop:"10px"}}>{isbnError}</p>
                </form> */}
                </Box>
            </Modal>
            
            {textbooks.results && <Textbooks textbooks={textbooks}/>}
            {/* <Ad></Ad> */}
                <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center",
                    '& .MuiPaginationItem-root': {color:"royalBlue", borderColor: "#9c9a9a"},
                }} >
                    <Pagination
                        onChange={handleChange}
                        page={page}
                        color="primary" 
                        variant = "outlined"
                        shape="rounded" 
                        count={textbooks.pageCount}
                        defaultPage={1}
                        sx={{pb:1}}
                        />
                    {textbooks.pageCount > 0 && <Typography style={{fontWeight:100, fontSize:12}}>Showing {page} out of {textbooks.pageCount} pages</Typography> }
                </Stack>
            </>}
            
                
        </textbooks>
    </div>
  );
};

export default SearchResults;