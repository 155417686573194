import axios from 'axios';
import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {IoIosStar} from 'react-icons/io';
import "./StarRating.css";
 
const StarRating = ({chapterId}) => {
    const [rating,setRating]=useState(0);
    const [hover, setHover]=useState(null);
    const [description,setDescription]=useState('');
    const [error,setError]=useState("");
    const [open,setOpen]=useState(false);
    const [success,setSuccess]=useState("");
 
    const addReviewHandler = async (e) => {
        e.preventDefault();
 
        try {
            await axios.get('/api/auth/refresh-token').then(res => axios.put(`/api/chapters/${chapterId}/addReview`,
            {
                rating,
                description,
                chapterId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data.accessToken}`
                },
            }));
 
            setRating(0);
            setDescription('');
            setOpen(false);
            setSuccess("Thanks for the review!");
            setTimeout(() => {
                setSuccess("");
            }, 2500);
        } catch (error) {
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
                setRating(0);
                setDescription('');
                setOpen(false);
            }, 2500);
        }
    }
 
    return (
        <div className="ratingComponent">
            <div className="container">
                {[...Array(5)].map((star,i) =>{
                    const realVal = i+1
                    return(
                    <label>
                        <input type="radio" name="rating" value={realVal} onClick={()=>setRating(realVal)}/>
                        <IoIosStar className="star" size="32" color={realVal <= (hover || rating) ? "#ffc107" : "#e4e5e9"} onClick={() => setOpen(true)} onMouseEnter={() => setHover(realVal)} onMouseLeave={()=>setHover(null)}/>
                    </label>
                    );
                })}
            </div>
            {open && <Form onSubmit={addReviewHandler}>
                <div className="container">
                    <Form.Group controlId="description">
                        <Form.Control
                            value={description}
                            onChange={(e)=>setDescription(e.target.value)}
                            as="textarea"
                            placeholder="Share..."
                        />
                    </Form.Group>
                    </div>
                    {error && <div className="error-message">{error}</div>}
 
                    <Button className ="but" type="submit">Add Review</Button>
                </Form>}
            {success && <div className="success-message">{success}</div>}
        </div>
        );
}
 
export default StarRating;