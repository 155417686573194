import React from 'react' ;
import { useState } from 'react';
import axios from 'axios';
import { Box, Typography, Card, Stack, Collapse, Alert, Divider } from '@mui/material';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded';
import { useHistory } from 'react-router-dom';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CardAI from '../common/CardAI';

const AiScreen = () => { 
    const history = useHistory();
    const [error, setError] = useState("");
    const handleCheckout = async (e) => { 
        e.preventDefault();
        if (localStorage.getItem("authToken")) {
            try { 
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data) {
                    const config = {headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token.data}` } };
                    const sub = await axios.get("/api/auth/subscription", config);
                    if (sub.data.subscription) { 
                        history("/summary")
                    } else {
                        const session = await axios.post("/api/stripe/checkout", {priceId: "price_1MMif3HLcQ3sA6QINFiPKAYn", sub: "normal"}, config);
                        if (session.data) {
                            window.open(session.data.url, "_self");
                        }
                    }
                } 
            } catch (err) {
                if (err.response.data.message) {
                    setError(err.response.data.message);
                    setTimeout(() => {setError("");}, 3000);
                }
            }
        } else {
            setError("Please login to continue");
            setTimeout(() => {setError("");}, 3000);
        }
    }

    return (
        <Box p={2} pl={30} bgcolor="#f4f7fb">
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <Typography fontWeight="bold" variant="h3" mt={2} mb={2} >AI Tools</Typography>
            <Divider variant="fullWidth" color="#374e69" />
            <br/>
            <Typography variant="h5" fontWeight="bold" mb={2}> Reading </Typography>
            <Stack direction="row" spacing={12}>
                <CardAI title="Text Summarizer" desc="Summarize long and tedious articles into something more manageable!" icon={<DescriptionRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>} onClick={handleCheckout}/>
                <Card onClick={  () => history.push("/ai/outline") }
                        sx={{ boxShadow:2, borderRadius: 2, height:220, width:320, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
                    <FormatAlignLeftRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>
                    <Stack p={3} pt={0}>
                        <Typography fontWeight="bold" fontSize="20px">Essay Outliner</Typography>
                        <Typography fontSize="16px">Create a detailed outline to streamline the essay-writing process!</Typography>
                    </Stack>
                </Card>
                <Card onClick={  () => history.push("/ai/outline") }
                        sx={{ boxShadow:2, borderRadius: 2, height:220, width:320, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
                    <NotesRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>
                    <Stack p={3} pt={0}>
                        <Typography fontWeight="bold" fontSize="20px">Text Simplifier</Typography>
                        <Typography fontSize="16px">Read and understand any text, no matter how complex, with ease!</Typography>
                    </Stack>
                </Card>
            </Stack>
            <br/><br/>
            <Typography variant="h5" fontWeight="bold" mb={2}> Writing </Typography>
            <Stack direction="row" spacing={12}>
                <Card onClick={  () => history.push("/ai/outline") }
                        sx={{ boxShadow:2, borderRadius: 2, height:220, width:320, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
                    <FormatAlignLeftRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>
                    <Stack p={3} pt={0}>
                        <Typography fontWeight="bold" fontSize="20px">Essay Outliner</Typography>
                        <Typography fontSize="16px">Create a detailed outline to streamline the essay-writing process!</Typography>
                    </Stack>
                </Card>
            </Stack>
            <br/><br/>
            <Typography variant="h5" fontWeight="bold" mb={2}> Studying </Typography>
            <Stack direction="row" spacing={12} mb={10}>
                <Card onClick={  () => history.push("/ai/outline") }
                        sx={{ boxShadow:2, borderRadius: 2, height:220, width:320, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
                    <QuizRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>
                    <Stack p={3} pt={0}>
                        <Typography fontWeight="bold" fontSize="20px">Flashcard Generator</Typography>
                        <Typography fontSize="16px">Upload your notes and convert important concepts into flashcards!</Typography>
                    </Stack>
                </Card>
                <Card onClick={ handleCheckout }
                        sx={{ boxShadow:2, borderRadius: 2, height:220, width:300, '&:hover': {border: 2, boxShadow: 0, borderColor:"primary.dark", cursor: "pointer"}}}>
                    <QuestionAnswerRoundedIcon sx={{fontSize: 80, color: "primary.main", mt: 2, ml: 2}}/>
                    <Stack p={3} pt={0}>
                        <Typography fontWeight="bold" fontSize="20px">Test Generator</Typography>
                        <Typography fontSize="16px">Transform your notes into possible exam questions and solutions!</Typography>
                    </Stack>
                </Card>
                
            </Stack>
        </Box>
    )
}

export default AiScreen;