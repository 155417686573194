import "./HomeScreen.css";
import { ReactComponent as Summary } from '../svg/summary.svg';
import { ReactComponent as Next } from '../svg/next.svg';
import { SvgIcon, Typography, Stack, Box , Button, Fab} from '@mui/material';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { useHistory } from 'react-router-dom';
//import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
//import { Link } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Alert, Collapse, Card} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from "axios"
//import Ad from '../ads/Ad';
//import UseScript from '../ads/UseScript';
import React from "react";
//import { Helmet } from "react-helmet";


const fabStyle = {
    color: 'common.white',
    bgcolor: '#33365b',
    boxShadow: false,
    '&:hover': {
      bgcolor: '#33365b',
      cursor:"initial"
    },
}

/*const testRefresh = () => {
    axios.get(
        "/api/auth/refresh-token",
    );
}*/

const HomeScreen = () => {
    const [open, setOpen] = React.useState(true);
    const width1650 = useMediaQuery('(max-width:1650px)');
    const minWidth1000 = useMediaQuery('(min-width:1000px)');
    const history = useHistory();

    const [query, setQuery] = React.useState("");
    axios.get(`/api/textbooks/years`).then(res => setQuery(res.data+"")).catch(error => console.log(error));
    
    const applyHandler = () => {
        window.open("https://bit.ly/EZread");
    }

    const searchHandler = () => {
        history.push(`/search/?query=${query}&subject=&publisher=&edition=&year=&page=1&sortBy=Default`);
    }

    const pricingHandler = () => {
        history.push(`/pricing`);
    }

    return (
        <Box style={{overflow: "hidden"}}>
            {/*<Collapse in={open}>
                <Alert severity="warning" variant="filled" color="error" onClose={() => {setOpen(false);}} >
                As our site is in beta-testing, we are collecting basic data from our users in order to improve overall experience. 
                    <strong> If you have an ad-blocker please disable it.</strong> Thank you!
                </Alert>
            </Collapse>*/}
            <Collapse in={open}>
                <Alert severity="info" variant="filled" color="info" onClose={() => {setOpen(false);}} >
                Interested in contributing to our collection of textbook summaries? Click the <Link underline="hover" onClick={applyHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}> <b>Apply</b></Link> button!
                </Alert>
            </Collapse>
            {/*<Ad/>*/}
            <Stack ml={"10vw"} mr={"8vw"} direction='row' sx={{display: 'flex', justifyContent: 'center', mt:"5vh"}}>
                <Box>
                    {!width1650 ?
                        <>
                            <Typography sx={{fontFamily: "Rubik", fontSize:60, fontWeight: "900", color:"#fff"}}>
                                Simple & comprehensive <br></br>summaries of your <br></br>textbooks...
                            </Typography>
                            <Typography sx={{fontFamily: "Rubik", fontSize:60, fontWeight: 900, color:"#fff"}}>
                                All in one place!
                            </Typography>
                        </>: 
                        <>
                            {minWidth1000 ? <>
                                <Typography sx={{fontFamily: "Rubik", fontSize:45, fontWeight: "900", color:"#fff"}}>
                            Simple & comprehensive <br></br> summaries of your <br></br>textbooks...
                            </Typography>
                            <Typography sx={{fontFamily: "Rubik", fontSize:45, fontWeight: 900, color:"#fff"}}>
                                All in one place!
                            </Typography>
                            </>: 
                            <>
                            <Typography sx={{fontFamily: "Rubik", fontSize:32, fontWeight: "900", color:"#fff"}}>
                            Simple & comprehensive <br></br> summaries of your <br></br>textbooks...
                            </Typography>
                            <Typography sx={{fontFamily: "Rubik", fontSize:32, fontWeight: 900, color:"#fff"}}>
                                All in one place!
                            </Typography>
                            </>
                            }
                            
                        </>
                    }
                    <br></br>
                    <Typography className="desc" sx={{fontFamily: "Rubik", fontSize:16, fontWeight: 200, width:"55%", color:"#fff"}}>
                        Scour through a library containing hundreds of expert chapter summaries of the world's most popular textbooks.
                    </Typography>
                    <br></br>
                    <Link underline="none" onClick={searchHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><Button className="desc" variant="contained" size="large" endIcon={<SvgIcon component={Next} inheritViewBox sx={{ml:2}}/>}
                    sx={{borderRadius: 10, p:"14px", px:"22px", mt:1, fontWeight:"bold", backgroundColor: "#3671E9", textTransform: "none", fontSize:18, fontFamily: "Rubik"}}>
                    Search now!
                    </Button></Link>
                </Box>
                {minWidth1000 && (<>{width1650 ? <SvgIcon component={Summary} inheritViewBox sx={{fontSize:"450px"}}/>
                : <SvgIcon component={Summary} inheritViewBox sx={{fontSize:"600px"}}/>}</>) }
            </Stack>
            { minWidth1000 ?
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)',  ml: "10vw", color:"white", mt:"6vh", mb:4}} >
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <BookmarkAddedRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900, color:"#fff", ml:2, mt:1}}>
                                750+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Chapter Summaries
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <BorderColorRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900,color:"#fff", ml:2, mt:1}}>
                                30+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Verified Summarizers
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <SchoolRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900,color:"#fff", ml:2, mt:1}}>
                                1,000+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Supported Institutions
                        </Typography>
                    </Box>
                </Stack>
            </Box>
                :
                <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(3, 1fr)', gap: 2, ml: "10vw", color:"white", mt:4, mb:4}} >
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <BookmarkAddedRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900, color:"#fff", ml:2, mt:1}}>
                                750+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Chapter Summaries
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <BorderColorRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900,color:"#fff", ml:2, mt:1}}>
                                30+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Verified Summarizers
                        </Typography>
                    </Box>
                </Stack>
                <Stack direction='row'>
                    <Fab disableRipple size="large" sx={fabStyle} style={{width:78, height:78}}>
                        <SchoolRoundedIcon color="info" fontWeight="500px" fontSize="large"/>
                    </Fab>
                    <Box>
                        <Typography className="desc" variant="h4" sx={{fontFamily: "Rubik", fontWeight:900,color:"#fff", ml:2, mt:1}}>
                                1,000+
                        </Typography>
                        <Typography sx={{ml:2, fontWeight:100, fontSize:12}}> 
                            Supported Institutions
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            }
            {!width1650 ?
                    <Stack sx={{backgroundColor:"#101d2c"}}>
                        <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"5vh", fontWeight:"bold", fontSize: 38, color:"#fff"}}> 
                                95% of students who use EZread summaries report higher grades
                        </Typography>
                        <Stack width = "50vw">
                            <Stack direction="row" spacing={"6vw"}>
                                <Stack width = "50vw">
                                    <Typography width="50vw" sx={{ml: "14vw", fontFamily: "Rubik", mt:"5vh", fontWeight:"bold", fontSize: 30, color:"royalBlue"}}> 
                                        Study more efficiently
                                    </Typography>
                                    <Typography sx={{fontFamily: "Rubik", mt:"3vh", fontSize: 18, color:"#fff", mb: "2vh", ml:"14vw"}}> 
                                        Research shows that university students need to study 30 hours a week to succeed.
                                        On average, students only have the time to study about 15 hours a week. To make matters worse,
                                        college textbooks that are meant to help students study are often filled with unnecessary information.
                                    </Typography>
                                    <Typography sx={{ mb: "2vh", fontFamily: "Rubik", fontSize: 18, color:"#fff", ml:"14vw"}}>
                                        Crafted by vetted scholars who know what content to prioritize, EZread's textbook summaries
                                        save countless hours of study time every week. EZread summaries empower students to fully utilize
                                        their textbooks and prepare for their exams.
                                    </Typography>
                                    <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px", ml: "14vw"}}>
                                        <Button variant="contained" size="large"
                                        sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold", backgroundColor: "#3671E9", textTransform: "none", fontSize:18, fontFamily: "Rubik"}}>
                                        Get started!
                                        </Button>
                                    </Link>
                                </Stack>
                                    <img  alt="student"
                                        src="https://images.pexels.com/photos/3807755/pexels-photo-3807755.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                        style={{height:"350px", width: "auto", marginTop: "8vh"}}
                                    />
                                
                            </Stack>
                            <Stack direction="row" spacing={"6vw"}>
                                <img alt="library"
                                    src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                    style={{height:"300px", width: "auto", marginTop: "10vh", marginLeft: "14vw" }}
                                >
                                </img>
                                <Stack width = "40vw">
                                    <Typography width="50vw" sx={{fontFamily: "Rubik", mt:"10vh", fontWeight:"bold", fontSize: 30, color:"royalBlue"}}> 
                                        An ever-growing library
                                    </Typography>
                                    <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 18,  mt:"3vh", color:"#fff"}}>
                                        Become a part of the 20,000+ students that rely on EZread to help relieve school stress and fatigue!
                                    </Typography>
                                    <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 18, color:"#fff"}}>
                                        Even if we don't have your textbook in our database, chances are we are working on it right now.
                                    </Typography>
                                    <Link underline="none" onClick={() => window.open("https://forms.gle/CqptswkAArgD5svRA")} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "220px"}}>
                                        <Button variant="contained" size="large"
                                        sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold",
                                        backgroundColor: "#3671E9", textTransform: "none", fontSize:18, fontFamily: "Rubik"}}>
                                        Request a textbook
                                        </Button>
                                    </Link>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"10vh", fontWeight:"950", fontSize: 28, color:"#fff"}}> 
                                Pricing starting as low as $4.99, cancel anytime
                        </Typography>
                        <Box sx={{ display:"flex", justifyContent:"center", mt:"2vh"}}>
                            <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px"}}>
                                <Button variant="contained" size="large"
                                sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold",
                                backgroundColor: "#3671E9", textTransform: "none", fontSize:18, fontFamily: "Rubik"}}>
                                Sign up now
                                </Button>
                            </Link>
                        </Box>
                        
                        <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"8vh", fontWeight:"950", fontSize: 38, color:"#fff"}}> 
                                What students are saying about EZread
                        </Typography>
                        
                        <Stack direction="row" spacing={"3vw"} my="5vh" sx={{display: 'flex', justifyContent: 'center', mb:"6vw"}}>
                            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                <img src={`${process.env.PUBLIC_URL}/james.webp`} alt="james" style={{width:"290px", height:"220px", borderRadius: 0}} />
                                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                        <Typography fontSize="16px" fontWeight="bold" color="white">
                                        "Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                                        </Typography>
                                        <Typography fontSize="15px" mt="15px" color="white">
                                        - James, Stanford '23 
                                        </Typography>
                                </Stack>
                            </Card>
                            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                <img src={`${process.env.PUBLIC_URL}/natalia.webp`} alt="natalia" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                    <Typography fontSize="16px" fontWeight="bold" color="white">
                                    "With EZread summaries, I'm able to get a good night's rest before my exam
                                    instead of stressing over self-studying my textbooks."
                                    </Typography>
                                    <Typography fontSize="15px" mt="15px" color="white">
                                    - Natalia, Emory '25 
                                    </Typography>
                                </Stack>
                            </Card>
                            <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                <img src={`${process.env.PUBLIC_URL}/rohan.webp`} alt="rohan" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                    <Typography fontSize="16px" fontWeight="bold" color="white">
                                    "I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                                    </Typography>
                                    <Typography fontSize="15px" mt="15px" color="white">
                                    - Rohan, Vanderbilt '24 
                                    </Typography>
                                </Stack>
                            </Card>
                        </Stack>
                    </Stack>: 
                        <>
                            {minWidth1000 ? 
                            <Stack sx={{backgroundColor:"#101d2c"}}>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"5vh", fontWeight:"bold", fontSize: 36, color:"#fff"}}> 
                                        95% of students who use EZread summaries
                                </Typography>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", fontWeight:"bold", fontSize: 36, color:"#fff"}}> 
                                        report higher grades
                                </Typography>
                                <Stack width = "50vw">
                                    <Stack direction="row" spacing={"6vw"}>
                                        <Stack width = "50vw">
                                            <Typography width="50vw" sx={{ml: "10vw", fontFamily: "Rubik", mt:"5vh", fontWeight:"bold", fontSize: 28, color:"royalBlue"}}> 
                                                Study more efficiently
                                            </Typography>
                                            <Typography sx={{ml: "10vw", fontFamily: "Rubik", mt:"3vh", fontSize: 16, color:"#fff", mb: "2vh"}}> 
                                                Research shows that university students need to study 30 hours a week to succeed.
                                                On average, students only have the time to study about 15 hours a week. To make matters worse,
                                                college textbooks that are meant to help students study are often filled with unnecessary information.
                                            </Typography>
                                            <Typography sx={{ml: "10vw", mb: "2vh", fontFamily: "Rubik", fontSize: 16, color:"#fff"}}>
                                                Crafted by vetted scholars who know what content to prioritize, EZread's textbook summaries
                                                save countless hours of study time every week. EZread summaries empower students to fully utilize
                                                their textbooks and prepare for their exams.
                                            </Typography>
                                            <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px", ml: "10vw"}}>
                                                <Button variant="contained" size="large"
                                                sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold", backgroundColor: "#3671E9", textTransform: "none", fontSize:16, fontFamily: "Rubik"}}>
                                                Get started!
                                                </Button>
                                            </Link>
                                        </Stack>
                                        <img alt="student"
                                            src="https://images.pexels.com/photos/3807755/pexels-photo-3807755.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                            style={{height:"320px", width: "auto", marginTop: "7vh"}}
                                        >
                                        </img>
                                    </Stack>
                                    <Stack direction="row" spacing={"6vw"}>
                                        <img alt="library"
                                            src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                            style={{height:"300px", width: "auto", marginTop: "10vh", marginLeft: "10vw" }}
                                        >
                                        </img>
                                        <Stack width = "40vw">
                                            <Typography width="50vw" sx={{fontFamily: "Rubik", mt:"10vh", fontWeight:"bold", fontSize: 28, color:"royalBlue"}}> 
                                                An ever-growing library
                                            </Typography>
                                            <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 16,  mt:"3vh", color:"#fff"}}>
                                                Become a part of the 20,000+ students that rely on EZread to help relieve school stress and fatigue!
                                            </Typography>
                                            <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 16, color:"#fff"}}>
                                                Even if we don't have your textbook in our database, chances are we are working on it right now.
                                            </Typography>
                                            <Link underline="none" onClick={() => window.open("https://forms.gle/CqptswkAArgD5svRA")} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "220px"}}>
                                                <Button variant="contained" size="large"
                                                sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold",
                                                backgroundColor: "#3671E9", textTransform: "none", fontSize:16, fontFamily: "Rubik"}}>
                                                Request a textbook
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"10vh", fontWeight:"950", fontSize: 26, color:"#fff"}}> 
                                        Pricing starting as low as $4.99, cancel anytime
                                </Typography>
                                <Box sx={{ display:"flex", justifyContent:"center", mt:"2vh"}}>
                                    <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px"}}>
                                        <Button variant="contained" size="large"
                                        sx={{borderRadius: 2, p:"14px", px:"22px", mt:1, fontWeight:"bold",
                                        backgroundColor: "#3671E9", textTransform: "none", fontSize:16, fontFamily: "Rubik"}}>
                                        Sign up now
                                        </Button>
                                    </Link>
                                </Box>
                                
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"9vh", fontWeight:"950", fontSize: 36, color:"#fff"}}> 
                                        What students are saying about EZread
                                </Typography>
                                
                                <Stack direction="row" spacing={"3vw"} my="5vh" sx={{display: 'flex', justifyContent: 'center', mb:"6vw"}}>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/james.webp`} alt="james" style={{width:"290px", height:"220px", borderRadius: 0}} />
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                                <Typography fontSize="16px" fontWeight="bold" color="white">
                                                "Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                                                </Typography>
                                                <Typography fontSize="15px" mt="15px" color="white">
                                                - James, Stanford '23 
                                                </Typography>
                                        </Stack>
                                    </Card>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/natalia.webp`} alt="natalia" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                            <Typography fontSize="16px" fontWeight="bold" color="white">
                                            "With EZread summaries, I'm able to get a good night's rest before my exam
                                            instead of stressing over self-studying my textbooks."
                                            </Typography>
                                            <Typography fontSize="15px" mt="15px" color="white">
                                            - Natalia, Emory '25 
                                            </Typography>
                                        </Stack>
                                    </Card>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/rohan.webp`} alt="rohan" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                            <Typography fontSize="16px" fontWeight="bold" color="white">
                                            "I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                                            </Typography>
                                            <Typography fontSize="15px" mt="15px" color="white">
                                            - Rohan, Vanderbilt '24 
                                            </Typography>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Stack>: 
                            <Stack sx={{backgroundColor:"#101d2c"}}>
                                <Box px={2}>
                                    <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"5vh", fontWeight:"bold", fontSize: 25,color:"#fff", textAlign: "center"}}> 
                                            95% of students who use
                                    </Typography>
                                    <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", fontWeight:"bold", fontSize: 25, color:"#fff", textAlign: "center"}}> 
                                            EZread summaries
                                    </Typography>
                                    <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", fontWeight:"bold", fontSize: 25, color:"#fff", textAlign: "center"}}> 
                                            report higher grades
                                    </Typography>
                                </Box>
                                
                                    <Stack spacing={"3vh"} sx={{display:"flex", alignItems: "center"}}>
                                        <img alt="student"
                                            src="https://images.pexels.com/photos/3807755/pexels-photo-3807755.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                            style={{height:"auto", width: "280px", marginTop: "5vh"}}
                                        >
                                        </img>
                                        <Stack width = "86vw">
                                            <Typography width="50vw" sx={{fontFamily: "Rubik", mt:"1vh", fontWeight:"bold", fontSize: 22, color:"royalBlue"}}> 
                                                Study more efficiently
                                            </Typography>
                                            <Typography sx={{fontFamily: "Rubik", mt:"3vh", fontSize: 14, color:"#fff", mb: "2vh"}}> 
                                                Research shows that university students need to study 30 hours a week to succeed.
                                                On average, students only have the time to study about 15 hours a week. To make matters worse,
                                                college textbooks that are meant to help students study are often filled with unnecessary information.
                                            </Typography>
                                            <Typography sx={{ mb: "2vh", fontFamily: "Rubik", fontSize: 14, color:"#fff"}}>
                                                Crafted by vetted scholars who know what content to prioritize, EZread's textbook summaries
                                                save countless hours of study time every week. EZread summaries empower students to fully utilize
                                                their textbooks and prepare for their exams.
                                            </Typography>
                                            <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px"}}>
                                                <Button variant="contained" size="large"
                                                sx={{borderRadius: 2, p:"10px", px:"20px", mt:1, fontWeight:"bold", backgroundColor: "#3671E9", textTransform: "none", fontSize:14, fontFamily: "Rubik"}}>
                                                Get started!
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={"3vh"} sx={{display:"flex", alignItems: "center"}}>
                                        <img alt="library"
                                            src="https://images.pexels.com/photos/1106468/pexels-photo-1106468.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                            style={{height:"auto", width: "280px", marginTop: "8vh"}}
                                        >
                                        </img>
                                        <Stack width = "86vw">
                                            <Typography width="50vw" sx={{fontFamily: "Rubik", mt:"1vh", fontWeight:"bold", fontSize: 22, color:"royalBlue"}}> 
                                                An ever-growing library
                                            </Typography>
                                            <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 14,  mt:"3vh", color:"#fff"}}>
                                                Become a part of the 20,000+ students that rely on EZread to help relieve school stress and fatigue!
                                            </Typography>
                                            <Typography sx={{mb: "2vh", fontFamily: "Rubik", fontSize: 14, color:"#fff"}}>
                                                Even if we don't have your textbook in our database, chances are we are working on it right now.
                                            </Typography>
                                            <Link underline="none" onClick={() => window.open("https://forms.gle/CqptswkAArgD5svRA")} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "220px"}}>
                                                <Button variant="contained" size="large"
                                                sx={{borderRadius: 2, p:"10px", px:"20px", mt:1, fontWeight:"bold",
                                                backgroundColor: "#3671E9", textTransform: "none", fontSize:14, fontFamily: "Rubik"}}>
                                                Request a textbook
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"8vh", fontWeight:"950", fontSize: 20, color:"#fff"}}> 
                                        Pricing starting as low as $4.99,
                                </Typography>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", fontWeight:"950", fontSize: 20, color:"#fff"}}> 
                                        cancel anytime
                                </Typography>
                                <Box sx={{ display:"flex", justifyContent:"center", mt:"2vh"}}>
                                    <Link underline="none" onClick={pricingHandler} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}, width: "158px"}}>
                                        <Button variant="contained" size="large"
                                        sx={{borderRadius: 2, p:"10px", px:"20px", mt:1, fontWeight:"bold",
                                        backgroundColor: "#3671E9", textTransform: "none", fontSize:14, fontFamily: "Rubik"}}>
                                        Sign up now
                                        </Button>
                                    </Link>
                                </Box>
                                
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", mt:"7vh", fontWeight:"950", fontSize: 22, color:"#fff"}}> 
                                        What students are saying
                                </Typography>
                                <Typography sx={{display:"flex", justifyContent:"center",fontFamily: "Rubik", fontWeight:"950", fontSize: 22, color:"#fff"}}> 
                                        about EZread
                                </Typography>
                                
                                <Stack spacing={"3vh"} mt="3vh" sx={{display: 'flex', alignItems: 'center', mb:"6vw"}}>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/james.webp`} alt="james" style={{width:"290px", height:"220px", borderRadius: 0}} />
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                                <Typography fontSize="16px" fontWeight="bold" color="white">
                                                "Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                                                </Typography>
                                                <Typography fontSize="15px" mt="15px" color="white">
                                                - James, Stanford '23 
                                                </Typography>
                                        </Stack>
                                    </Card>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/natalia.webp`} alt="natalia" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                            <Typography fontSize="16px" fontWeight="bold" color="white">
                                            "With EZread summaries, I'm able to get a good night's rest before my exam
                                            instead of stressing over self-studying my textbooks."
                                            </Typography>
                                            <Typography fontSize="15px" mt="15px" color="white">
                                            - Natalia, Emory '25 
                                            </Typography>
                                        </Stack>
                                    </Card>
                                    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
                                        <img src={`${process.env.PUBLIC_URL}/rohan.webp`} alt="rohan" style={{width:"290px", height:"220px", borderRadius: 0}}/>
                                        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                                            <Typography fontSize="16px" fontWeight="bold" color="white">
                                            "I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                                            </Typography>
                                            <Typography fontSize="15px" mt="15px" color="white">
                                            - Rohan, Vanderbilt '24 
                                            </Typography>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Stack>
                            }
                            
                        </>
                    }
            
        </Box>

    );
};


export default HomeScreen;