import { useLocation } from 'react-router-dom';
import { useState, useEffect} from 'react';
import "./ChapterScreen.css";
import StarRating from '../Rating/StarRating';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import {zoomPlugin} from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { Box, Alert, Collapse, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import LoadingSpinner from '../common/LoadingSpinner';
import Link from '@mui/material/Link';
import AudioPlayer from '../common/AudioPlayer';

import { useHistory } from "react-router-dom";

const ChapterScreen = ({match}) => {
    const location = useLocation();
    const { chapters } = location.state;
    const [pageLoading, setPageLoading] = useState(true);
    const [pdfFile, setPdfFile] = useState(null);
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const zoomPluginInstance = zoomPlugin();
    const {ZoomInButton, ZoomOutButton, ZoomPopover} = zoomPluginInstance;
    const {CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPage } = pageNavigationPluginInstance;
    const [pdfWidth, setPdfWidth] = useState("60%");
    let history = useHistory();
    const [currChap, setCurrChap] = useState(0);
    const [unlocked, setLock] = useState(true);
    const [subscribed, setSubscribed] = useState(true);
    const [upgrade, setUpgrade] = useState(true);
    const [audioSrc, setAudioSrc] = useState(null);

    const config = {
        responseType: "arraybuffer",
        responseEncoding: "binary",
    };

    //catch error
    useEffect(() => {

        const unlockChapter = async () => {
            try{
                const chapId = match.params.chapterId;
                const loggedInfo = await axios.get('/api/auth/refresh-token');
                const chap = await axios.get(`/api/chapters/${chapId}/comments`);
                setCurrChap(chap.data.chapterNumber-1)
                if (chap.data.chapterNumber > 1) {
                    if (!(loggedInfo.data.accessToken)) {
                        setLock(false);
                        setPageLoading(true);
                    } else {
                        const cnfg = {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${loggedInfo.data.accessToken}`
                            },
                        };
                        const usr = await axios.get(`/api/auth/user`, cnfg);
                        if (!usr.data.subscription) {
                            setLock(false);
                            setPageLoading(true);
                        } else if (usr.data.subscription === "basic") {
                            //console.log("bruh", usr.data.unlockedBooks[0])
                            if (!usr.data.unlockedBooks[0]) {
                                console.log("This chapter is locked. Would you like to unlock this textbook? (NOTE: With your current subscription plan, you can only only unlock one textbook's worth of chapter summaries.)")
                                setLock(true);
                                setSubscribed(false);
                                setPageLoading(true);
                                setUpgrade(true);
                            }
                            else if (usr.data.unlockedBooks[0] !== chap.data.textbook) {
                                //console.log("You have another textbook unlocked under your current subscription plan. Upgrade to access unlimited summaries by clicking here!", chap.data.textbook);
                                setUpgrade(false);//change to diff warning
                                setLock(true);
                                setSubscribed(true);
                                setPageLoading(true);
                            } else {
                                //console.log("match!");
                                getSearchResults();
                            }
                            
                        } else {
                            //console.log(usr.data.unlockedBooks);
                            setLock(true);
                            setSubscribed(true);
                            setUpgrade(true);
                            getSearchResults();
                        }
                    }
                } else {
                    setLock(true);
                    setSubscribed(true);
                    setUpgrade(true)
                    getSearchResults();
                }
            } catch (err) {
                console.log(err.response.data);
            }
            
        }

        const getSearchResults = async () => {
            try {
                setPageLoading(true);
                
                await axios.get(`/api/chapters/${match.params.chapterId}/comments`).then(async res => {
                    //setAudioSrc(res.data.audioFile);
                    const curConfig = {headers: { responseType: 'blob', "Content-Type": "application/json"} };
                    //const wavData = await axios.get(`/api/audio/${currentPlaylist[i]._id}`, curConfig);
                    await fetch(`/api/audio/${res.data._id}`, curConfig)
                    .then(response => response.blob())
                    .then(async blob => {
                        const url = URL.createObjectURL(blob);
                        //console.log(url)
                        setAudioSrc(url)
                        
                    })
                    .catch(error => console.error(error));

                    await axios.get(`/api/chapters/${res.data.pdfFile}`, config).then(async res1 => {
                        var file = new Blob([(res1.data)], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);
                        await setPdfFile(fileURL);
                    });
                });
                setPageLoading(false);
            } catch (err) {
                console.log(err);
            }
        };

        window.scrollTo(0, 0);
        unlockChapter();
        //getSearchResults();
    }, [match.params.chapterId]);

    const handleAddTextbook = async (e) => {
        e.preventDefault();
        //console.log(axios.post('/api/stripe/checkout', {'priceId': "price_1LvyddLcLaskqiyHckOgxLxr"}));
        try {
            
            const chapId = match.params.chapterId;
            const loggedInfo = await axios.get('/api/auth/refresh-token');
            const chap = await axios.get(`/api/chapters/${chapId}/comments`);

            const textbook = chap.data.textbook;
            const cnfg1 = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${loggedInfo.data.accessToken}`
                },
            };
            

            const usr = await axios.get(`/api/auth/user`, cnfg1);
            const usr_id = usr.data._id;

            const res = await axios.post('/api/sub/addTextbook', { "textbook": textbook, "user_id": usr_id} );
            if (res.status === 200) {
                setSubscribed(true);
                window.location.reload();
            }
        } catch (error) {
            console.log(error.response.data);
        }
        //const res = await axios.post('/api/stripe/checkout');
        //axios.post(`/api/stripe/checkout`).then(res => {console.log(res.data);}).catch(error => console.log(error));
        //console.log(res);
    }

    const handleZoom = (e) => {
        console.log(`Zoom to ${e.scale}`);
        var percentage = e.scale * 620
        setPdfWidth (`${percentage}px`);
    };

    const handleChap = (index, id) => {
        setCurrChap(index);
        history.push({ pathname: `/${id}/comments`, state: { chapters: chapters }});
    }

    const handleChapNext = () => {
        if (chapters[currChap] !== chapters[chapters.length -1]) {
            history.push({ pathname: `/${chapters[currChap+1]}/comments`, state: { chapters: chapters }});
            setCurrChap(currChap+1);
        }
    }

    const handleChapPrior = () => {
        if (currChap !== 0) {
            history.push({ pathname: `/${chapters[currChap-1]}/comments`, state: { chapters: chapters }});
            setCurrChap(currChap-1);
        }
    }


    return (
        <Box>
            {/*<Dropdown title="Choose chapter" items={items} />*/}
            
            <Stack direction="row" spacing={2} py={2} sx={{alignItems:"center", justifyContent: "center"}}>
                    { (currChap !== 0) ?
                        <Button variant="outlined" startIcon={<NavigateBeforeRoundedIcon/>} onClick={handleChapPrior}>Back </Button> :
                        <Button disabled sx={{backgroundColor:"rgba(255, 255, 255, 0.25)"}} variant="outlined" startIcon={<NavigateBeforeRoundedIcon/>} onClick={handleChapPrior}>Back </Button>
                    }
                    <TextField
                    focused="true"
                    size="small"
                    variant="outlined"
                    select
                    label="Chapter"
                    value={chapters[currChap]}
                    //onChange={(e) => handleChap(e.currentTarget)}
                    sx={{
                        "& .MuiFormHelperText-root": {color:"primary"},
                        "& .MuiSelect-select": {color: "#fff", border: "#fff", width:"8ch"},
                        "& .MuiSelect-icon": {color:"#fff"},
                        "& .MuiMenuItem-root": {borderColor:"#fff"}, 
                    }}
                >
                    
                    {chapters.map((id, index) => (<MenuItem value={id} onClick={(e) => {handleChap(index, id)}}>Chapter {index+1}</MenuItem>))}
                    </TextField>
                    { (chapters[currChap] !== chapters[chapters.length -1]) ? 
                    <Button variant="outlined" endIcon={<NavigateNextRoundedIcon/>} onClick={handleChapNext}>Next</Button> :
                    <Button disabled sx={{backgroundColor:"rgba(255, 255, 255, 0.25)"}} variant="outline" endIcon={<NavigateNextRoundedIcon/>} onClick={handleChapNext}>Next</Button>
                    }
            </Stack>
            {!pageLoading && <Box bgcolor="#f5f5f5" p={2}>
                <AudioPlayer sub={localStorage.getItem("sub")} src={audioSrc}/>
            </Box>}
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", backgroundColor:"#eee"}}>
                <div style={{width:`${pdfWidth}`, border: "1px solid #eeeeee", marginTop: "4px"}}>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundColor: '#eeeeee',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '4px',
                        }}>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOutButton />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <ZoomPopover />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <ZoomInButton />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <GoToFirstPageButton />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>

                            <div style={{ padding: '0px 2px', width: '4rem' }}>
                                <CurrentPageInput />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPageButton />
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <GoToLastPageButton />
                            </div>
                    </div>
                    <Collapse in={!upgrade}>
                        <Alert severity="warning" variant="filled" sx={{'& .MuiAlert-icon': {fontSize:"30px"}, fontSize: "18px"}} color="warning" onClose={() => {setUpgrade(true);}} >
                            You have another textbook unlocked under your current subscription plan. Upgrade to access unlimited summaries by clicking <Link underline="hover" onClick={() => {history.push("/pricing");}} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><b>here</b></Link>! 
                        </Alert>
                    </Collapse>
                    <Collapse in={!unlocked}>
                        <Alert severity="warning" variant="filled" color="warning" sx={{'& .MuiAlert-icon': {fontSize:"26px"}, fontSize: "18px"}} onClose={() => {setLock(true);}} >
                            Chapter {currChap+1} locked. If subscribed, please log in. Otherwise, unlock all chapter summaries by clicking <Link underline="hover" onClick={() => {history.push("/pricing");}} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><b>here</b></Link>! 
                        </Alert>
                    </Collapse>
                    <Collapse in={!subscribed}>
                        <Alert severity="info" variant="filled" sx={{'& .MuiAlert-icon': {fontSize:"30px"}, fontSize: "18px"}} color="info" onClose={() => {setSubscribed(true);}} >
                            This chapter is locked. Unlock all of this texbook's chapter summaries by clicking <Link underline="hover" onClick={handleAddTextbook} sx= {{color: "common.white", '&:hover': {cursor:"pointer"}}}><b>here</b></Link>! 
                            <Typography>(NOTE: With your current subscription plan, you can only only unlock one textbook's worth of chapter summaries.)</Typography>
                        </Alert>
                    </Collapse>
                    {pageLoading ? <LoadingSpinner text={'Loading summary...'} /> : <>
                    {/* <Ad/> */}
                        <div className="viewer">
                        {pdfFile&&<><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                                <Viewer fileUrl={pdfFile} onZoom={handleZoom}
                                plugins={[pageNavigationPluginInstance,zoomPluginInstance]} />
                            </Worker></>}
                        </div>
                    </>
                    }
                </div>

            </div>
            <Box sx= {{p:2, display: "flex", justifyContent: "center"}}>
                <StarRating chapterId={match.params.chapterId}/>
            </Box>
            
        </Box>
    );

};

export default ChapterScreen;
/**
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "./ChapterScreen.css";
import Dropdown from '../Dropdown/Dropdown';
import StarRating from '../Rating/StarRating';
import axios from 'axios';
import useScript from '../../services/useScript';

const ChapterScreen = ({match}) => {
    const location = useLocation();
    const { chapters } = location.state;
    const [isLoading, setLoading] = useState(true);
    const [chapterData, setChapterData] = useState({});

    useScript('../components/pdfViewer/pdfViewer.js');
    useScript('https://mozilla.github.io/pdf.js/build/pdf.js');

    //catch error
    useEffect(() => {
        axios.get(`/api/chapters/${match.params.chapterId}/comments`).then(res => {
            setChapterData(res.data);
            setLoading(false);
        });
    }, [chapterData]);

    const items = chapters.map(function (id, index) {
        const realIdx = index + 1;
        return { id: id, value: 'Chapter ' + realIdx }
    });

    if(isLoading){
        return <h2>Loading...</h2>;
    }

    return (
        <>
        <head>
            <link
                rel="stylesheet"
                href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
                integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                crossorigin="anonymous"
                />
        </head>
            <StarRating chapterId={match.params.chapterId}/>
            <Dropdown title="Choose chapter" items={items} />

            <div style={{display:"flex", alignItems:"center", justifyContent:"center", backgroundColor:"#eee"}}>
                <div style={{width:"65%", border: "1px solid #eeeeee"}}>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundColor: '#eeeeee',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '4px',
                        }}>
                            <div style={{ padding: '0px 2px' }}>
                                <button id="prev-page">
                                    <i className="fas fa-arrow-circle-left"></i>
                                </button>
                            </div>

                            <div style={{ padding: '0px 2px' }}>
                                <button id="next-page">
                                    <i className="fas fa-arrow-circle-right"></i>
                                </button>
                            </div>

                            <span className="page-info">
                                Page <span id="page-num"></span> of <span id="page-count"></span>
                            </span>

                            <canvas id="pdf-render"></canvas>
                    </div>
                </div>
            </div>
        </>
    );

};

export default ChapterScreen;
*/