import React from 'react';

import Textbook from "./textbook/textbook";
import './textbooks.css'

const Textbooks = ({textbooks}) => {
    return (
        <div id="outer">
            {textbooks && textbooks.results ? (textbooks.results.map((textbook) => (
                <Textbook
                    id={textbook._id}
                    title={textbook.title}
                    authors={textbook.authors}
                    edition={textbook.edition}
                    publisher={textbook.publisher}
                    year={textbook.year}
                    cover={textbook.cover}
                    type={textbook.type}
                    numChapters={textbook.chapters.length}
                    isbn={textbook.isbn}
                    chapters={textbook.chapters}
                />
            ))):<div></div>}
        </div>
    );
};

export default Textbooks;