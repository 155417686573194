import * as React from 'react';
import Link from "@mui/material/Link";
import { Typography, Toolbar, Stack, Box, AppBar} from '@mui/material';
import "./Navbar.css";
import TOS from '../../legalDocs/TOS.pdf';
import Privacy from '../../legalDocs/Privacy.pdf';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {

const minWidth500 = useMediaQuery('(min-width:500px)');

  return (
      <AppBar p={"10vw"} position="static" sx={{ mt: 0, top: 'auto', bottom: 0}}>
        <Toolbar sx={{backgroundColor: "#252642"}}>
            { minWidth500 ? <Stack py={2} ml={"8vw"}>
                <Box  sx={{width:"100vw", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap:1, justifyContent:"center"}}>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"72px"}}>
                            Company 
                            <Divider sx={{bgcolor: '#fff', mb: 1}}/>
                        </Typography>
                       
                        <Link color='inherit' fontSize='14px' href='/about' underline='none'>About</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}} onClick={() => window.open("https://bit.ly/EZread")} underline='none'>Apply</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://forms.gle/b5Zj51kKFeQcLbHq7")} underline='none'>Contact</Link>
                        <Link color='inherit' fontSize='14px' href='/faq' underline='none'>FAQ</Link>
                        <Link color='inherit' fontSize='14px' href='/pricing' underline='none'>Pricing</Link>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"64px"}}>
                            Socials<Divider sx={{bgcolor: '#fff', mb: 1}}/>
                        </Typography>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://www.linkedin.com/company/79508710")} underline='none'>LinkedIn</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://twitter.com/EZreadLLC")} underline='none'>Twitter</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://instagram.com/ezread.io/")} underline='none'>Instagram</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://www.tiktok.com/@ezread.io?lang=en")} underline='none'>TikTok</Link>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"84px"}}>
                            Business
                            <Divider sx={{bgcolor: '#fff', mb: 1}}></Divider>
                        </Typography>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open(TOS)} underline='none'>Terms of Use</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open(Privacy)} underline='none'>Privacy</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://forms.gle/AMu1JyNEAgPGp8rc6")} underline='none'>DMCA Removal</Link>
                    </Stack>
                </Box>
                <Typography fontSize={13} mt={2}>Copyright © 2022 EZread LLC</Typography>
            </Stack> : 
            <Stack py={2} ml={"2vw"}>
                <Box  sx={{width:"100vw", display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap:1, justifyContent:"center"}}>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"72px"}}>
                            Company 
                            <Divider sx={{bgcolor: '#fff', mb: 1}}/>
                        </Typography>
                    
                        <Link color='inherit' fontSize='14px' href='/about' underline='none'>About</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}} onClick={() => window.open("https://bit.ly/EZread")} underline='none'>Apply</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://forms.gle/b5Zj51kKFeQcLbHq7")} underline='none'>Contact</Link>
                        <Link color='inherit' fontSize='14px' href='/faq' underline='none'>FAQ</Link>
                        <Link color='inherit' fontSize='14px' href='/pricing' underline='none'>Pricing</Link>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"64px"}}>
                            Socials<Divider sx={{bgcolor: '#fff', mb: 1}}/>
                        </Typography>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://www.linkedin.com/company/79508710")} underline='none'>LinkedIn</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://twitter.com/EZreadLLC")} underline='none'>Twitter</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://instagram.com/ezread.io/")} underline='none'>Instagram</Link>
                            <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://www.tiktok.com/@ezread.io?lang=en")} underline='none'>TikTok</Link>
                    </Stack>
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px' sx={{width:"84px"}}>
                            Business
                            <Divider sx={{bgcolor: '#fff', mb: 1}}></Divider>
                        </Typography>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open(TOS)} underline='none'>Terms of Use</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open(Privacy)} underline='none'>Privacy</Link>
                        <Link color='inherit' fontSize='14px' sx={{'&:hover': {cursor:"pointer"}}}onClick={() => window.open("https://forms.gle/AMu1JyNEAgPGp8rc6")} underline='none'>DMCA Removal</Link>
                    </Stack>
                </Box>
                <Typography fontSize={13} mt={2}>Copyright © 2022 EZread LLC</Typography>
            </Stack>
            }
            
        </Toolbar>
      </AppBar>
  );
}

export default Footer;