import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import "./FAQScreen.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from 'react-select';
import { Button, Typography, CardHeader, Alert, Collapse, Divider} from '@mui/material';
import colleges from "../Dropdown/collegeDropdown.js"
import majors from "../Dropdown/majorsDropdown.js"
import "./ProfileScreen.css";

const ProfileScreen = () => {
    const history = useHistory();
    const [openAlert, setOpenAlert] = useState(false);
    const [uni, setUni] = useState("");
    const [major, setMajor] = useState("");
    const [gender, setGender] = useState("");
    const [gradyear, setGradYear] = useState("");
    const [degree, setDegree] = useState("");
    const [error, setError] = useState("");

    const profileHandler = async (e) => {
        e.preventDefault();

        try {
            const {data} = await axios.get(`/api/auth/refresh-token`);
            const res = await axios.put(
                "/api/auth/profile",
                {
                    uni,
                    major,
                    gender,
                    gradyear,
                    degree
                },
                {headers: {"Content-Type": "application/json", Authorization: `Bearer ${data.accessToken}`}}
            );
            window.location.reload();
        } catch (error) {
            setError(error.response.data.error);
            setOpenAlert(true);
            setTimeout(() => {
                setError("");
                setOpenAlert(false);
            }, 5000);
        }
    };

    const billingHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.get('/api/auth/refresh-token');
            if (res.data.accessToken) {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${res.data.accessToken}`
                    },
                };
                const usr = await axios.get(`/api/auth/user`, config);
                if (usr.data.customerId) {
                    const portalSession = await axios.post('/api/stripe/customer-portal', {'customerId': usr.data.customerId,});
                    window.open(portalSession.data.url, "_self")
                } else {
                    setError("To access your Billing Portal, please purchase a subscription!");
                    setOpenAlert(true);
                }
            } else {
                history.push("");
            }
        } catch (error) {
            console.log(error.response.data);
        }
    }

    //const minWidth850 = useMediaQuery('(min-width:850px)');
    
    useEffect(() => {
        const setVals = async () => {
            try {
                const res = await axios.get('/api/auth/refresh-token');
                if (res.data.accessToken) {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${res.data.accessToken}`
                        },
                    };
                    const usr = await axios.get(`/api/auth/user`, config);
                    setUni(usr.data.uni);
                    setMajor(usr.data.major);
                    setGender(usr.data.gender);
                    setGradYear(usr.data.gradyear);
                    setDegree(usr.data.degree);
                } else {
                    history.push("");
                }

            } catch (err) {
                console.log(err)
            }
        };
        setVals();
    }, []);

  return (
    <Box sx={{display: 'flex', justifyContent: 'center',}}>
        <Box mt="6vh" mb="10vh">
            <Card sx={{p:"20px", width: "420px"}}>
            <form onSubmit={profileHandler}>
                <Collapse in={openAlert}>
                    <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenAlert(false); setError("")}} >
                        {error}
                    </Alert>
                </Collapse>
                <h2 className="profile-screen__title" style = {{fontWeight: 550, fontFamily: "Rubik", marginBottom:5}} > Edit your Profile </h2>
                <Typography fontSize="18px"> School</Typography>
                <div className="form-group">
                    <Select required placeholder={uni} options={colleges} onChange={(e) => setUni(e.label)} />
                </div>
                <Typography fontSize="18px">Major</Typography>
                <div className="form-group">
                    <Select placeholder={major} options={majors} onChange={(e) => setMajor(e.value)} />
                </div>
                <Typography fontSize="18px">Gender</Typography>
                <div className="form-group">
                    <Select placeholder={gender} options={[{"label": "Male"}, {"label": "Female"}, {"label": "Other"}]} onChange={(e) => setGender(e.label)} />
                </div>
                <Typography fontSize="18px">Graduation Year</Typography>
                <div className="form-group">
                    <Select placeholder={gradyear} options={
                        [{"label": "2022"}, {"label": "2023"}, {"label": "2024"}, {"label": "2025"}, 
                        {"label": "2026"}, {"label": "2027"}, {"label": "2028"}, {"label": "Other"}]
                        } onChange={(e) => setGradYear(e.label)} />
                </div>
                <Typography fontSize="18px">Degree Type</Typography>
                <div className="form-group">
                    <Select placeholder={degree} options={
                        [{"label": "High School"}, {"label": "Undergraduate"}, {"label": "Graduate"}, {"label": "Other"}]
                        } 
                        onChange={(e) => setDegree(e.label)}/>
                </div>
                <Button
                    type="submit"
                    tabIndex={3}
                    sx={{textTransform: "none", mt:"12px", mb:"8px"}}
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                    style={{fontFamily: "Rubik", float:"left"}} 
                >
                    Update
                </Button>
            </form>
            <Divider color="#000"></Divider>
            <form onSubmit={billingHandler}>
                <Button type="submit" fullWidth color="secondary" size="large" sx={{mt:"15px", textTransform:"none", fontFamily: "Rubik"}} variant="contained">
                    Access your Billing Portal
                </Button>
            </form>
        </Card>

        </Box>
    </Box>
  );
};

export default ProfileScreen;
